import pluralize from 'pluralize-ru'
import cyrillicToTranslit from 'cyrillic-to-translit-js'
import { ruLocaleOnly } from 'config'

export const humanize = function (string = '') {
  return string.replace(/\w/, c => c.toUpperCase()).replace(/_/g, ' ')
}

export const prepareUrl = function (url) {
  if (/^(https?:)?\/\//.test(url)) {
    return url
  }
  return `http://${url}`
}

export function plural(count, values = []) {
  values = [].concat(values)
  ;[0, 1, 2, 3].forEach(index => {
    if (values[index] === void 0) {
      values[index] = values[0] || ''
    }
  })

  return pluralize(count, ...values)
}

export const firstCharToUpperCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const cyrillicTitle = title => {
  if (!title) return ''
  title = cyrillicToTranslit()
    .transform(title.trim(), '-')
    .replace(/[^a-zA-Z0-9\-\u0621-\u064A]+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-/, '')
    .toLocaleLowerCase()
  return `${title}`
}

export const getLocaleFromUrl = str => str.match(/^\/(en|ar|es|ru)/)[1]

export const convertPhoneNumberToPhoneLink = phoneNumber => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '')
  return `tel:+${numericPhoneNumber}`
}

export const currencySymbol = ruLocaleOnly ? '₽' : '€'
