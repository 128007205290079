import * as C from './consts'
import * as targets from './actions'

export default ({ getState, dispatch }) => {
  return next => action => {
    if (
      [C.CREATE_TARGET.SUCCESS, C.DESTROY_TARGET.SUCCESS].includes(action.type)
    ) {
      dispatch(targets.getTargets(action.slug))
      return next(action)
    } else {
      return next(action)
    }
  }
}
