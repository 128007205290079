import React from 'react';
import { DI } from 'modules';
import { IS_LANDING } from '../../config';

export function FooterBlockSocial(props) {
    const social = props.common.strings[IS_LANDING ? 'footer.landing.social' : 'footer.social'] || [];

    return (
        <div className="footer-block footer-block--social">
            <div className="footer-social">
                <div className="footer-social-list">
                    {social.map((item, index) => (
                        <div className="footer-social-item" key={index}>
                            <a
                                href={item.link}
                                className="footer-social__link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span
                                    className={`footer-social__ico footer-social__ico--${item.ico}`}
                                >
                                    {item.link}
                                </span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DI([])(FooterBlockSocial);
