import React from 'react'
import { withRouter } from 'react-router'
import { DI } from 'modules'
import { Modal } from 'components/modal'

import FormSignUp from 'components/form-sign-up'

export function SignUp(props) {
  return (
    <Modal onClose={props.onClose}>
      <div className="popup-sign-in">
        <FormSignUp {...props} />
      </div>
    </Modal>
  )
}

export default DI(['user'])(withRouter(SignUp))
