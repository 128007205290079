import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {isServer} from 'store';

const defaultProps = {
    on() {},
    excludes: [],
    includes: []
};
const propTypes = {
    on: PropTypes.func,
    excludes: PropTypes.array
};

class OuterClick extends React.Component {
    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
    }

    componentDidMount() {
        if (!isServer) {
            document.body.addEventListener('click', this.handler);
        }
        this.timeout = setTimeout(() => {
            this.cnt = OuterClick.cnt++;
            let el = ReactDOM.findDOMNode(this);
            if (el && el.setAttribute) {
                el.setAttribute('outer-cnt', this.cnt);
            }
        }, 0);
    }

    componentWillUnmount() {
        if (!isServer) {
            document.body.removeEventListener('click', this.handler);
            clearTimeout(this.timeout);
        }
    }

    handler(event) {
        let isExclude = this.props.excludes.some((selector) => {
            return event.target.closest(selector);
        });
        let isInclide = this.props.includes.some((selector) => {
            return event.target.closest(selector);
        });

        if (
            isInclide ||
            (!isExclude && !event.target.closest(`[outer-cnt="${this.cnt}"]`))
        ) {
            this.props.on(event);
        }
    }

    render() {
        return this.props.children;
    }
}

OuterClick.defaultProps = defaultProps;
OuterClick.propTypes = propTypes;
OuterClick.cnt = 0;

export default OuterClick;
