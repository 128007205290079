import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { Modal } from 'components/modal'

class ModalSpinner extends Component {
  render() {
    const active =
      this.props.user.purchasePossibility.pending ||
      this.props.user.paypalSubscriptionPay

    if (active || this.props.active) {
      return (
        <Modal>
          <div className="popup-with-ico">
            <span className="popup-sending__ico"></span>
            <span className="popup-sending__text">Please wait...</span>
          </div>
        </Modal>
      )
    } else {
      return null
    }
  }
}

export default DI(['user'])(ModalSpinner)
