import * as C from '../consts'
import * as M from '../models'
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'

/* MY CONTENT */

export const myContentEntities = api(
  C.GET_MY_CONTENT_PROJECTS,
  M.Entities,
  action => action.search.page,
  action => action.data
)

export const myContentData = boundReducer(
  {
    content: [],
  },
  {
    [C.GET_MY_CONTENT_PROJECTS.SUCCESS]: (state, action) => {
      const nextState = { ...state }

      nextState[action.search.page] = action.data.entities
      if (action.search.pages) {
        nextState.content = action.search.pages.reduce((result, page) => {
          result.push(...(nextState[page] || []))
          return result
        }, [])
      } else {
        nextState.content = action.data.entities
      }

      nextState.content = nextState.content.filter(({ entity }) => !!entity)

      return nextState
    },
  }
)

export const myContentMeta = api(C.GET_MY_CONTENT_PROJECTS, Object)

export const myContentRequests = api(C.GET_MY_CONTENT_REQUESTS, Array)

export const myContentOperationsEntity = api(
  C.GET_MY_CONTENT_OPERATIONS,
  Array,
  action => action.search.id,
  action => action.data
)

export const myContentOperationGroups = api(
  C.GET_MY_CONTENT_OPERATIONS,
  Array,
  action => action.search.id,
  action => {
    return Object.values(
      action.data.reduce((result, item) => {
        if (!result.hasOwnProperty(item.agreement.id)) {
          result[item.agreement.id] = {
            agreement: item.agreement,
            operations: [],
          }
        }
        result[item.agreement.id].operations.push(item)
        return result
      }, {})
    )
  }
)

export const downloadRequestedVideo = api(C.DOWNLOAD_REQUESTED_VIDEO, Object)

/* / MY CONTENT */
