import * as C from '../consts'
import { boundReducer } from 'redux-upgrader'

export const paypalSubscriptionPay = boundReducer(false, {
  [C.PP_SUBSCRIPTIONS_PAY.REQUEST]: true,
  [C.PP_SUBSCRIPTIONS_PAY.SUCCESS]: false,
  [C.PP_SUBSCRIPTIONS_PAY.FAILURE]: false,
  [C.PP_SUBSCRIPTIONS_PROCEED.REQUEST]: true,
  [C.PP_SUBSCRIPTIONS_PROCEED.FAILURE]: false,
})
