import React from 'react'
import Outer from 'components/outer-click'
import Component from 'components/component'

import modal from './base'

class Modal extends Component {
  render() {
    return (
      <div
        className="popup-container"
        style={{ display: 'block', overflow: 'auto' }}
      >
        <div className="popup" style={{ zIndex: 9999 }}>
          <div className="popup-inner" style={{ position: 'relative' }}>
            {this.props.onClose && (
              <a href="#" className="popup__close" onClick={this.props.close} />
            )}
            {this.props.children}
          </div>
        </div>
        <div className="overlay" onClick={this.props.close} />
      </div>
    )
  }
}

export default modal(Modal)
