import React from 'react'
import { useFrontload } from 'react-frontload'
import { isServer } from 'store'

function preloadFunc(func, props) {
  const { location } = props.history
  const haveSurf = location.state?.prevPage !== location.pathname
  const canPreload = document.readyState === 'complete' || isServer || haveSurf

  return canPreload ? func(props) : Promise.resolve()
}

const frontLoad = (Component, key) => props => {
  const { data, frontloadMeta } = useFrontload(key, () =>
    preloadFunc(Component.preload, props)
  )

  return <Component {...props} />
}

export default frontLoad
