import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'

import { withRouter, matchPath } from 'react-router'
import { videosList, videosFilter } from 'config/routes'

const en = {
  'mixed_content.title': 'Mixed content',
  'mixed_content.tooltip':
    'The most complete videofeed contains materials in other languages. Select viewing mode:',
  'mixed_content.false': 'EN only',
  'mixed_content.true': 'EN + RU',
}

const ru = {
  'mixed_content.title': 'Контент на английском',
  'mixed_content.tooltip':
    'Самая полная видеолента с материалами на английском языке',
  'mixed_content.false': 'Не показывать',
  'mixed_content.true': 'Показывать',
}

const ar = {
  'mixed_content.title': 'محتوى باللغة الانجليزية',
  'mixed_content.tooltip': 'محتوى يشمل اللغة الانجليزية ايضاً',
  'mixed_content.false': 'اخفاء',
  'mixed_content.true': 'عرض',
}

const es = {
  'mixed_content.title': 'Contenido en inglés',
  'mixed_content.tooltip': 'Todo el contenido (inglés y español)',
  'mixed_content.false': 'No mostrar',
  'mixed_content.true': 'Mostrar',
}

export class HeaderLangBlock extends Component {
  onClick = ev => {
    const isLocale = this.props.locale

    const { with_international_mix, ...params } = this.props.common.searchParams
    const value = JSON.parse(ev.target.value)

    const paramModify =
      matchPath(this.props.location.pathname, videosList) ||
      matchPath(this.props.location.pathname, videosFilter)

    let link = this.props.location.pathname
    link = link.replace(/^\/\w{2}/, `/${isLocale}`)

    if (value) {
      params.with_international_mix = true
    } else {
      delete params.with_international_mix
    }

    if (paramModify || with_international_mix !== value) {
      this.props.actions.common.updateSearchParams(params, true, link)
    } else {
      this.props.actions.common.updateSearchParams(params, true, isLocale)
    }
  }

  boxTranlation() {
    let lang = this.props.locale

    switch (lang) {
      case 'en':
        return en
      case 'ru':
        return ru
      case 'es':
        return es
      case 'ar':
        return ar
    }
  }

  render() {
    const locale = this.boxTranlation()
    const { strings, searchParams } = this.props.common
    const { with_international_mix } = searchParams

    return (
      <div className="header-lang-block">
        <div className="iwrap">
          <div className="header-lang-block__text">
            {locale['mixed_content.tooltip']}
          </div>
          <form className="header-lang-block__select">
            <input
              type="radio"
              id={`show${this.props.locale}Posts1`}
              className="lang-panel-radio"
              name="with_international_mix"
              onClick={this.onClick}
              value={false}
              checked={!with_international_mix}
            />
            <label
              htmlFor={`show${this.props.locale}Posts1`}
              className="lang-panel-button"
            >
              {locale['mixed_content.false']}
            </label>
            <input
              type="radio"
              id={`show${this.props.locale}Posts2`}
              className="lang-panel-radio"
              name="with_international_mix"
              onClick={this.onClick}
              value={true}
              checked={with_international_mix}
            />
            <label
              htmlFor={`show${this.props.locale}Posts2`}
              className="lang-panel-button"
            >
              {locale['mixed_content.true']}
            </label>
          </form>
        </div>
      </div>
    )
  }
}

export default DI()(withRouter(HeaderLangBlock))
