import React, { Fragment } from 'react'
import Component from 'components/component'
import { DI } from 'modules'
import FooterBots from './footer-bots'
import RejectionWarningModal from 'components/card-rejection/rejection-warning-modal'
import FooterBlockSocial from './footer-block-social'
import FooterBlockMenu from './footer-block-menu'
import { IS_LANDING } from '../../config'

export class Footer extends Component {
  state = {
    showModal: false,
  }

  render() {
    const { strings } = this.props.common
    const footer = strings[IS_LANDING ? 'footer.landing.dashboard' : 'footer.dashboard'] || {}
    const contacts = strings[IS_LANDING ? 'footer.landing.contacts' : 'footer.contacts'] || []
    const SocialsBlock = this.props.common.IsFooterSocials

    return (
      <footer
        className={this.classList(
          'footer',
          !SocialsBlock && 'footer--with-bots'
        )}
      >
        {this.props.common.cardWarningModal && <RejectionWarningModal />}

        {!SocialsBlock && (
          <Fragment>
            <div className="footer-top">
              <div className="iwrap">
                <div className="footer-up-to-date">
                  <p>{footer.desc}</p>
                </div>
                <FooterBots />
              </div>
            </div>
            <div className="footer-separator"></div>
          </Fragment>
        )}

        <div className="iwrap">
          <div className="footer-blocks">
            <div className="footer-contacts">
              {contacts.map(item => (
                <div
                  key={item.key}
                  className={`footer-block-flex footer-block footer-block--${item.key}`}
                >
                  <span className="footer-block__title">{item.label}</span>
                  <div>
                    {item.phone && (
                      <div className="footer-block-item">
                        <a
                          href={`tel:${item.phone.replace(' ', '')}`}
                          className="footer__phone"
                        >
                          {item.phone}
                        </a>
                      </div>
                    )}
                    <div className="footer-block-item">
                      <a href={`mailto:${item.email}`} className="footer__link">
                        {item.email}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="footer-nav">
              <FooterBlockSocial />
              <FooterBlockMenu />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default DI(['common'])(Footer)
