import * as C from './consts'
// import DEPRECATED_moment from 'moment-timezone';
import { boundApiReducer as api } from 'redux-upgrader'
import { DateTime } from 'luxon'

export const list = api(C.GET_LOCALES, Array)

export function timezones(state = defaultTimezones()) {
  return state
}

// export const timezones = api(C.GET_TIMEZONES, Array, null, (action) =>
//     action.data.time_zones
//         .map((code) => ({
//             code: code,
//             offset: DateTime.setZone(code).offset,
//         }))
//         .sort(function (a, b) {
//             if (a.offset > b.offset) {
//                 return 1;
//             }
//             if (a.offset < b.offset) {
//                 return -1;
//             }
//             return 0;
//         })
// );

function defaultTimezones() {
  return {
    payload: [
      'Africa/Algiers',
      'Africa/Cairo',
      'Africa/Casablanca',
      'Africa/Harare',
      'Africa/Johannesburg',
      'Africa/Monrovia',
      'Africa/Nairobi',
      'America/Argentina/Buenos_Aires',
      'America/Bogota',
      'America/Caracas',
      'America/Chicago',
      'America/Chihuahua',
      'America/Denver',
      'America/Godthab',
      'America/Guatemala',
      'America/Guyana',
      'America/Halifax',
      'America/Indiana/Indianapolis',
      'America/Juneau',
      'America/La_Paz',
      'America/Lima',
      'America/Los_Angeles',
      'America/Mazatlan',
      'America/Mexico_City',
      'America/Monterrey',
      'America/Montevideo',
      'America/New_York',
      'America/Phoenix',
      'America/Puerto_Rico',
      'America/Regina',
      'America/Santiago',
      'America/Sao_Paulo',
      'America/St_Johns',
      'America/Tijuana',
      'Asia/Almaty',
      'Asia/Baghdad',
      'Asia/Baku',
      'Asia/Bangkok',
      'Asia/Chongqing',
      'Asia/Colombo',
      'Asia/Dhaka',
      'Asia/Hong_Kong',
      'Asia/Irkutsk',
      'Asia/Jakarta',
      'Asia/Jerusalem',
      'Asia/Kabul',
      'Asia/Kamchatka',
      'Asia/Karachi',
      'Asia/Kathmandu',
      'Asia/Kolkata',
      'Asia/Krasnoyarsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuwait',
      'Asia/Magadan',
      'Asia/Muscat',
      'Asia/Novosibirsk',
      'Asia/Rangoon',
      'Asia/Riyadh',
      'Asia/Seoul',
      'Asia/Shanghai',
      'Asia/Singapore',
      'Asia/Srednekolymsk',
      'Asia/Taipei',
      'Asia/Tashkent',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Tokyo',
      'Asia/Ulaanbaatar',
      'Asia/Urumqi',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yekaterinburg',
      'Asia/Yerevan',
      'Atlantic/Azores',
      'Atlantic/Cape_Verde',
      'Atlantic/South_Georgia',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Darwin',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
      'Australia/Sydney',
      'Etc/GMT+12',
      'Etc/UTC',
      'Europe/Amsterdam',
      'Europe/Athens',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Bratislava',
      'Europe/Brussels',
      'Europe/Bucharest',
      'Europe/Budapest',
      'Europe/Copenhagen',
      'Europe/Dublin',
      'Europe/Helsinki',
      'Europe/Istanbul',
      'Europe/Kaliningrad',
      'Europe/Kiev',
      'Europe/Lisbon',
      'Europe/Ljubljana',
      'Europe/London',
      'Europe/Madrid',
      'Europe/Minsk',
      'Europe/Moscow',
      'Europe/Paris',
      'Europe/Prague',
      'Europe/Riga',
      'Europe/Rome',
      'Europe/Samara',
      'Europe/Sarajevo',
      'Europe/Skopje',
      'Europe/Sofia',
      'Europe/Stockholm',
      'Europe/Tallinn',
      'Europe/Vienna',
      'Europe/Vilnius',
      'Europe/Volgograd',
      'Europe/Warsaw',
      'Europe/Zagreb',
      'Europe/Zurich',
      'Pacific/Apia',
      'Pacific/Auckland',
      'Pacific/Chatham',
      'Pacific/Fakaofo',
      'Pacific/Fiji',
      'Pacific/Guadalcanal',
      'Pacific/Guam',
      'Pacific/Honolulu',
      'Pacific/Majuro',
      'Pacific/Midway',
      'Pacific/Noumea',
      'Pacific/Pago_Pago',
      'Pacific/Port_Moresby',
      'Pacific/Tongatapu',
    ]
      .map(code => ({
        code: code,
        offset: DateTime.local().setZone(code).offset,
      }))
      .sort(function (a, b) {
        if (a.offset > b.offset) {
          return 1
        }
        if (a.offset < b.offset) {
          return -1
        }
        return 0
      }),
  }
}
