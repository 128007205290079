import React, { Fragment, useState } from 'react'
import { withRouter } from 'react-router'

import { DI } from 'modules'
import Component from 'components/component'
import Validator from 'components/validator'
import Form from 'common/components/form/new-form'
import { humanize } from 'helpers/strings'
import * as routes from 'config/routes'
import { Link } from 'components/link'
import { tos, privacyPolicy } from 'config/routes'
// import InvisibleRecaptcha from 'components/invisible-recaptcha';
import { IS_LANDING, EXTERNAL_LANDING_ID } from 'config'

const onValidateForm = () => {}

function parseErrors(body) {
  const errors = []
  Object.keys(body.errors).forEach(key => {
    errors.push(
      ...body.errors[key].map(text => [humanize(key), text].join(' '))
    )
  })
  return errors
}

export function FormSignUp(props) {
  // const recaptchaRef = React.createRef();
  const [form, setForm] = useState({
    email: '',
    newsletter_notify: 'on',
  })
  const [loading, setLoading] = useState(false)
  const [serverErrors, setErrors] = useState([])

  const onRecaptchaPassed = result => {
    const formData = {
      ...form,
      is_landing_user: IS_LANDING ? true : false,
      ...(IS_LANDING ? { external_landing_id: EXTERNAL_LANDING_ID } : {}),
      profile_locale: props.common.locale,
    }

    props.actions.user
      .signUp(formData)
      .then(resp => {
        props.onClose && props.onClose()
        props.history.push(`/${props.common.locale}/check-email`)
      })
      .catch(err => {
        setErrors(parseErrors(err.response.body))
        setLoading(false)
      })
  }

  const onChange = (ev, form) => setForm(form)
  const onSubmit = (ev, form) => {
    if (form.agreeTerms && onValidateForm.panic()) {
      setLoading(true)
      onRecaptchaPassed()
      // recaptchaRef.current.execute(config.RECAPTCHA_KEY, {
      //     action: 'defaultAction'
      // });
    }
  }

  const strings = props.common.strings
  const buttonClassName = Component.classList(
    'button button--free',
    loading && '_loading'
  )

  /* const oauth = (type) => {
    if (type === 'facebook') {
      window.location.assign(`${API_HOST}/users/auth/facebook`)
    }
    if (type === 'google') {
      window.location.assign(`${API_HOST}/users/auth/google_oauth2`)
    }
    if (type === 'twitter') {
      window.location.assign(`${API_HOST}/users/auth/twitter`)
    }
  } */

  return (
    <Fragment>
      <span className="sign-in__title">{strings['signup.title']}</span>
      <div className="sign-in-text sign-in-text--pre">
        <p>
          {strings['signup.signin.before_link']}{' '}
          {props.openSignInModal ? (
            <a href="#" onClick={props.openSignInModal}>
              {strings['signup.signin.link.text']}
            </a>
          ) : (
            <Link to={routes.signin.getPath()} onClick={props.onClose}>
              {strings['signup.signin.link.text']}
            </Link>
          )}
        </p>
      </div>
      <div className="sign-in-form j-terms">
        <Form onChange={onChange} onSubmit={onSubmit}>
          <Validator
            value={form.email}
            validators={['isRequired', 'isEmail']}
            onValidateForm={onValidateForm}
            render={({ hasError, value, messages }) => (
              <Fragment>
                <div className="field">
                  <input
                    type="text"
                    className="input"
                    placeholder={strings['signup.email.placeholder']}
                    name="email"
                    value={value}
                    hasError={messages[0]}
                  />
                </div>
                {(hasError || serverErrors.length) && (
                  <div className="error">
                    {messages.concat(serverErrors)[0]}
                  </div>
                )}
              </Fragment>
            )}
          />
          <div className="field field--su-checkbox">
            <div className="checkbox">
              <input
                type="checkbox"
                checked={form.agreeTerms}
                className="checkbox__input"
                id="agreeTerms"
                name="agreeTerms"
              />
              <label htmlFor="agreeTerms" className="checkbox__label">
                {strings['signup.terms.text']}{' '}
                <Link to={tos.getPath()} onClick={() => props.onClose()}>
                  {strings['signup.terms.link']}
                </Link>
                {', '}
                {strings['signup.privacy.text']}
                <Link
                  to={privacyPolicy.getPath()}
                  onClick={() => props.onClose()}
                >
                  {strings['signup.privacy.link']}
                </Link>
              </label>
            </div>
          </div>
          {/* <div className="field field--su-checkbox">
            <div className="checkbox">
              <input
                type="checkbox"
                checked={form.newsletter_notify}
                className="checkbox__input"
                id="newsletter_notify"
                name="newsletter_notify"
              />
              <label htmlFor="newsletter_notify" className="checkbox__label">
                {strings['signup.newsletters.text']}
              </label>
            </div>
          </div> */}
          {/*<InvisibleRecaptcha*/}
          {/*    ref={recaptchaRef}*/}
          {/*    onPassed={() => onRecaptchaPassed()}*/}
          {/*    onFail={() => setLoading(false)}*/}
          {/*    actions={props.actions.recaptcha}*/}
          {/*/>*/}
          <div className="field field--submit">
            <button
              type="submit"
              className={buttonClassName}
              disabled={!form.agreeTerms}
            >
              {strings['signup.button.text']}
              <span className="button__loading">
                {strings['signup.button.loading']}
                ...
              </span>
            </button>
          </div>
        </Form>
      </div>
      <div className="sign-in-link sign-in-link--already">
        <span className="sign-in-link__label">
          {strings['signup.signin.will_get_link']}
        </span>
      </div>
      {/* <div className="sign-in-social">
        <div className="sign-in-social__text">
          {strings['signin.social.pretitle']}
        </div>
        <a href="#" onClick={() => oauth('facebook')}
                   className="sign-in-social__button sign-in-social__button--facebook">
                    {strings['signin.social.facebook']}
                                </a>
        <a
          href="#"
          onClick={() => oauth('twitter')}
          className="sign-in-social__button sign-in-social__button--twitter"
        >
          {strings['signin.social.twitter']}
        </a>
        <a
          href="#"
          onClick={() => oauth('google')}
          className="sign-in-social__button sign-in-social__button--google"
        >
          {strings['signin.social.google']}
        </a>
      </div> */}
    </Fragment>
  )
}

export default DI(['user', 'recaptcha'])(withRouter(FormSignUp))
