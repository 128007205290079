import * as config from 'config'
import { ConnectedRouter } from 'connected-react-router'
import init from 'init'
// import BrowserRouter from 'react-router-dom/BrowserRouter';
import React from 'react'
import { ActionCableProvider } from 'react-actioncable-provider'
import { hydrate } from 'react-dom'
import { createFrontloadState } from 'react-frontload'
import { loadableReady } from '@loadable/component'
import { Provider } from 'react-redux'
import App from './app'
import getAcceptedCookies from './helpers/cookies'
import createStore from './store'

const { store, history } = createStore()

loadableReady(() => {
  const cookiesList = getAcceptedCookies()

  const frontloadState = createFrontloadState.client({
    // inject client impl of api for use in data loading functions.
    // will probably make HTTP calls to the server
    context: {},

    // hydrate state from SSR
    serverRenderedData: window._frontloadData,
  })

  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ActionCableProvider url={config.WS_URI}>
          <App frontloadState={frontloadState} />
        </ActionCableProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
    () => {
      init(cookiesList)
    }
  )
})

history.listen((state, method) => {
  if (state.hasOwnProperty('state')) {
    const { scrollToTop = true } = state.state || {}
    if (scrollToTop) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
})

if (module.hot) {
  module.hot.accept()
}

// OfflinePluginRuntime.install();
