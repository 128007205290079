import React from 'react'
import { DI } from 'modules'

import { Modal } from 'components/modal'
import FormSignIn from 'components/form-sign-in'

export function SignIn(props) {
  return (
    <Modal onClose={props.onClose}>
      <div className="popup-sign-in">
        {/* <button
                    type="button"
                    className="popup__close"
                    onClick={props.onClose}
                />
                 */}
        <FormSignIn {...props} />
      </div>
    </Modal>
  )
}

export default DI(['user'])(SignIn)
