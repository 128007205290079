export default {
  [process.env.PRJ_ENV.toUpperCase()]: true,
}

export const API_HOST = process.env.API_HOST
export const VOD_HOST = process.env.VOD_HOST
export const LIVE_HOST = process.env.LIVE_HOST
export const STATIC_HOST = process.env.STATIC_HOST
export const STRIPE_API_KEY = process.env.STRIPE_API_KEY
export const WS_URI = process.env.WS_URI
export const COMMON_DOMAIN = process.env.COMMON_DOMAIN
export const STORAGE_HOST = process.env.STORAGE_HOST
export const FB_APP_ID = process.env.FB_APP_ID
export const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
export const VU_HOST = process.env.VU_HOST
export const MEDIA_CENTRE = process.env.MEDIA_CENTRE
export const CMS_HOST = process.env.CMS_HOST
export const PRJ_ENV = process.env.PRJ_ENV.toUpperCase()
export const isProd = ['production', 'yandex_prod'].includes(
  process.env.PRJ_ENV.toLowerCase()
)
export const MAIN_HOST = process.env.MAIN_HOST || ''
export const LANDING = process.env.LANDING
export const EXTERNAL_LANDING_ID = process.env.EXTERNAL_LANDING_ID
export const IS_LANDING = process.env.LANDING && process.env.LANDING.length > 0

export const ruLocaleOnly = process.env.COMMON_DOMAIN === 'ruptly.video'
