import * as C from './consts'

export function getTags(isLanding) {
  return {
    types: C.GET_TAGS,
    url: isLanding ? '/api/content/external_landing_tags' : `/api/content/tags`,
    cache: 1000 * 999,
  }
}

export const getTag = id => ({
  types: C.GET_TAG,
  url: `/api/content/tags/${id}`,
})

export function getEntityTag({ slug, page = 1, per_page = 12 }) {
  return {
    types: C.GET_ENTITY_TAG,
    url: `/api/content/tags/landings/${slug}`,
    search: { page, per_page },
  }
}

export function getFilteredEntityTag(params = {}) {
  return {
    types: C.GET_ENTITY_FILTERED_TAG,
    url: params.search
      ? '/api/content/video_projects/search'
      : '/api/content/video_projects',
    search: params,
  }
}

export const wsStatusChangeLandingLiveList = data => (dispatch, getState) => {
  const {
    tags,
    common: { searchParams },
  } = getState()
  const tag = tags.byPage.getValue(1).payload

  if (!data.tags.some(t => t.id === tag.tag.id)) {
    return
  }

  const pageIndex = searchParams.page || 1
  const lives = tag.lives
  const changedItem = (lives || []).find(item => item.id === data.id)

  let newData = {}

  if (changedItem) {
    if (
      data.status !== 'terminated' &&
      data.status !== 'canceled' &&
      data.visible
    ) {
      newData =
        data.status === 'live'
          ? {
              lives: [data, ...lives.filter(item => item.id !== changedItem.id)],
            }
          : {
              lives: lives
                .filter(item => item.id !== changedItem.id)
                .concat({ ...changedItem, ...data }),
            }
    } else {
      newData = {
        lives: lives.filter(item => item.id !== changedItem.id),
        lives_count: tag.lives_count - 1,
      }
    }
  } else if (data.visible) {
    newData = {
      lives: (lives || []).concat(data),
      lives_count: tag.lives_count + 1,
    }
  }

  dispatch({
    type: C.GET_ENTITY_TAG.SUCCESS,
    data: {
      ...tag,
      ...newData,
    },
    search: { page: pageIndex },
  })
}

export const setListViewTypeLanding = value => ({
  type: C.SET_LIST_VIEW_TYPE_LANDING,
  value,
})

export function followTag(tag_id) {
  return {
    types: C.FOLLOW_TAG,
    url: `/api/content/tags/${tag_id}/subscriptions`,
    method: 'POST',
  }
}

export function unfollowTag(tag_id) {
  return {
    types: C.FOLLOW_TAG,
    url: `/api/content/tags/${tag_id}/subscriptions`,
    method: 'DELETE',
  }
}
