export class Videos {
  videos = []
  count = 0
}

export class Video {
  title = ''
  tags = []
  selected_preview_image = {
    url: '',
    small: {},
    medium: {},
    large: {},
  }
}

export class Duration {
  max_duration = 9999999
  min_duration = 0
}

export function RelatedVideos() {
  return Array.from({ length: 5 }, (_, index) => ({
    additional_info: null,
    animated_preview_thumbnails: Array.from({ length: 24 }).fill(''),
    duration: null,
    external_name: index,
    format: 'flat',
    free_access: false,
    id: String(index),
    inner_id: index,
    last_changed_at: new Date().toUTCString(),
    limit_type: 'pool',
    published_at: null,
    rights_type: 'pool',
    selected_preview_image: {
      large: { url: '' },
      large_brand: { url: '' },
      medium: { url: '' },
      small: { url: '' },
      url: '',
    },
    tags: Array.from({ length: 2 }, (_, index) => ({
      id: index,
      active: false,
    })),
    title: '',
  }))
}

export class AvailableFormats {
  clean = []
  branded = []
  credit = []
}
