import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'
import Component from 'components/component'
import { getScrollbarWidth } from '../.../../../helpers/document'

let modalRoot

try {
  modalRoot = document.querySelector('#modal_wrapper')
} catch (err) {}

export default function modal(Cmp) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.el = document.createElement('div')
      this.el.style.position = 'absolute'
      this.el.style.zIndex = '2147483640'
    }

    close = ev => {
      const countModals = modalRoot.children.length
      const isLast = modalRoot.children[countModals - 1] === this.el

      if (this.props.onClose && isLast) {
        ev.preventDefault()
        this.props.onClose()
      }
    }

    componentDidMount() {
      modalRoot.appendChild(this.el)

      this.bodyStyle = {
        overflow: document.body.style.overflow,
        paddingRight: document.body.style.paddingRight,
      }

      this.scrollbarWidth = getScrollbarWidth()

      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = `${this.scrollbarWidth}px`

      document.addEventListener('keydown', this.handlePressEsc)
    }

    componentWillUnmount() {
      modalRoot.removeChild(this.el)
      if (modalRoot.children.length) {
        document.body.style.overflow = this.bodyStyle.overflow
      } else {
        document.body.style.overflow = ''
      }

      document.body.style.paddingRight = this.bodyStyle.paddingRight

      document.removeEventListener('keydown', this.handlePressEsc)
    }

    handlePressEsc = ev => {
      if (ev.keyCode === 27) {
        this.close(ev)
      }
    }

    renderOverlay() {
      return (
        <Fragment>
          <Cmp close={this.close} {...this.props} />
        </Fragment>
      )
    }

    render() {
      return createPortal(this.renderOverlay(), this.el)
    }
  }
}
