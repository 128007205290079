import * as C from '../consts'
import { boundApiReducer as api } from 'redux-upgrader'

export const myLists = api(C.GET_LISTS, Array)

export const myVideoLists = api(C.GET_VIDEO_LISTS, Array)

export const myListItems = api(C.GET_LIST_ITEMS, Array)

export const activeList = api(C.GET_ACTIVE_LIST, Array)

export const byPage = api(
  C.GET_LISTS,
  Array,
  action => action.search.page,
  action => action.data.lists
)

export const byPageListItems = api(
  C.GET_LIST_ITEMS,
  Array,
  action => action.search.page,
  action => action.data.lists
)
