import React from 'react'
import { DI } from 'modules'
import { Link as RouterLink } from 'react-router-dom'
import Component from 'components/component'

class LinkCmp extends Component {
  render() {
    const { common, actions, forwardedRef, ...props } = this.props

    if (props.to && typeof props.to === 'string' && props.to.match(/^http/)) {
      return <a target="_blank" href={props.to} {...props}></a>
    }

    return (
      <RouterLink
        {...props}
        ref={forwardedRef}
        to={`/${common.locale}${props.to}`
          .replace(/\/$/, '')
          .replace(/\/\?/, '?')}
      />
    )
  }
}

export const Link = DI(['common'])(
  React.forwardRef((props, ref) => {
    return <LinkCmp {...props} forwardedRef={ref} />
  })
)
