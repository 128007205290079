import React from 'react'

import { Modal } from 'components/modal'
import Form from 'common/components/form/new-form'
import Checkbox from 'components/form/checkbox'
import { DI } from '../../modules'

class WebPushModal extends React.Component {
  state = {
    notification_locales:
      this.props['web-push'].pushSettings.payload.notification_locales || [],
  }

  closePopup = ev => {
    ev && ev.preventDefault()
    this.props.actions['web-push'].toggleWebpushSettingsPopup()
  }

  onSubmit = (ev, form) => {
    const external_id = this.props['web-push'].pushSettings.payload.external_id
    this.props.actions['web-push'].setPushSettings(external_id, form)
    this.closePopup()
  }

  onChange = async (ev, form) => {
    const { notification_locales = [] } = form
    await this.setState({
      notification_locales,
    })
  }

  render() {
    const strings = this.props.common.strings
    const notification_locales = this.state.notification_locales
    const locales = this.props.locales.list.payload

    return (
      <Modal
        onClose={this.closePopup}
        classNameWrap=""
        popupClassName="popup popup--land"
        closeClassName="popup__close popup__close--land"
      >
        <div className="popup-sign-in">
          <span className="sign-in__title">
            {strings['push_settings.title']}
          </span>
          <div className="sign-in-text sign-in-text--pre">
            <p>{strings['push_settings.subtitle']}</p>
          </div>
          <div className="sign-in-form">
            <Form onSubmit={this.onSubmit} onChange={this.onChange}>
              {locales.map(locale => (
                <div key={locale.code} className="field">
                  <Checkbox
                    value={locale.code}
                    name="notification_locales[]"
                    checked={notification_locales.includes(locale.code)}
                  >
                    {locale.name}
                  </Checkbox>
                </div>
              ))}
              <div className="field field--submit">
                <button type="submit" className="button button--free">
                  {strings['page.confirmation.submit']}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    )
  }
}

export default DI(['locales', 'web-push'])(WebPushModal)
