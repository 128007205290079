import React from 'react';
import Component from 'components/component';

let index = 0;

class Checkbox extends Component {
    static defaultProps = {
        className: 'checkbox',
        onChange() {},
        onClick() {},
        labelModificators: [''],
        value: true,
        error: false,
    };

    id = 'checkbox-' + index++;

    render() {
        let {
            error,
            children,
            className,
            labelModificators,
            uncheckedValue,
            labelStyle,
            ...props
        } = this.props;
        return (
            <div
                className={this.classList(className, error && '_error')}
                style={this.props.style}
            >
                {this.props.hasOwnProperty('uncheckedValue') && (
                    <input
                        type="hidden"
                        name={this.props.name}
                        value={uncheckedValue}
                    />
                )}
                <input
                    type="checkbox"
                    className={this.classList(
                        `${className}__input`,
                        error && '_error'
                    )}
                    id={this.id}
                    {...props}
                />
                <label
                    htmlFor={this.id}
                    className={this.labelClassName}
                    style={labelStyle}
                >
                    {children}
                </label>
                {this.props.help}
            </div>
        );
    }

    get labelClassName() {
        return this.classList.apply(
            null,
            this.modificators(
                `${this.props.className}__label`,
                this.props.labelModificators
            )
        );
    }
}

export function Checkboxes(props) {
    return <div {...props} className="checkboxes" />;
}

export default Checkbox;
