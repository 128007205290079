export class Dashboard {
  banners = Array.from({ length: 4 }, (_, index) => ({
    breaking: false,
    created_at: new Date().toISOString(),
    custom_url: '',
    id: index,
    image: {
      small: { url: '' },
      tiny: { url: '' },
      url: '',
    },
    image_type: '',
    live_event_extid: '',
    locale: 'en',
    position: index,
    tag_id: null,
    target_link_to: '',
    title: '',
    updated_at: new Date().toISOString(),
    video_project_extid: '',
  }))
  live_events = []
  feed = []
  tags = []
}
