import * as C from './consts'

export function active(state = [], action) {
  const { type, ...item } = action
  switch (action.type) {
    case C.CREATE_NOTIFICATION: {
      return state.concat(item)
    }
    case C.DELETE_NOTIFICATION: {
      return state.filter(item => {
        return item.id !== action.id
      })
    }
    default:
      return state
  }
}
