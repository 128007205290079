import React from 'react'
import { Link } from 'components/link'
import { DI } from 'modules'
import { Link as LinkRouter } from 'react-router-dom'
import { withRouter } from 'react-router'

function HeaderClear({
  searchParams,
  withLocale,
  common,
  locales,
  location,
  actions,
}) {
  const { with_international_mix: mix } = searchParams || {}
  const search = mix ? `?with_international_mix=${mix}` : ''
  const logo = (
    <Link className="header-logo" to={`/${search}`}>
      <span className="header-logo__img header-logo__img--black" />
    </Link>
  )

  return (
    <header className="header header--clear">
      <div className="header-main">
        <div className="iwrap">
          <div className="header-inner">
            {withLocale ? (
              <table className="header-columns">
                <tbody>
                  <tr>
                    <td>{logo}</td>
                    <td>
                      <div className="header-langs">
                        {Array.isArray(locales.list.payload) &&
                          locales.list.payload.map(({ code }) => {
                            const link =
                              location.pathname.replace(
                                /^\/\w{2}/,
                                `/${code}`
                              ) + location.search

                            return (
                              <div key={code} className="header-lang">
                                {common.locale === code ? (
                                  <span className="header__lang _active">
                                    {code.toUpperCase()}
                                  </span>
                                ) : (
                                  <LinkRouter
                                    className="header__lang"
                                    onClick={() =>
                                      actions.common.setLocale(code)
                                    }
                                    to={link}
                                  >
                                    {code.toUpperCase()}
                                  </LinkRouter>
                                )}
                              </div>
                            )
                          })}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              logo
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default DI(['locales'])(withRouter(HeaderClear))
