import * as C from './consts'
import * as api from 'utils/api'

export function toggleWebpushSettingsPopup() {
  return {
    type: C.TOGGLE_WEBPUSH_SETTINGS_POPUP,
  }
}

export const getPushSettings = external_id => {
  return {
    types: C.GET_PUSH_SETTINGS,
    method: 'GET',
    url: `/api/webpush_notification_clients/${external_id}`,
  }
}

export const setPushSettings =
  (external_id, settings = {}) =>
  (d, g) => {
    const client = {
      external_id,
      ...settings,
    }

    d({
      type: C.GET_PUSH_SETTINGS.SUCCESS,
      data: client,
      params: [],
    })

    return api.post(
      C.SET_PUSH_SETTINGS,
      d,
      g,
      '/api/webpush_notification_clients/',
      { client }
    )
  }
