import React from "react";
import PageLoading from 'components/page-loading'
import loadable from "@loadable/component";
import { createRoute } from 'utils/routing'
import { LANDING } from '..'

const loading = () => <PageLoading />

/* Content */

export const dashboard = createRoute({
  path: '/:locale',
  component: loadable(() => import(/* webpackChunkName: "dashboard" */ 'pages/content/dashboard'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const landingDashboard = createRoute({
  path: '/:locale',
  component: loadable(() => import(/* webpackChunkName: "landing-dashboard" */ 'pages/content/landing'),
    {
      fallback: loading,
      resolveComponent: (imported, props) => () => {
        return React.createElement(imported.default, { ...props, slug: LANDING }, [])
      }
    }
  ),
  exact: true,
})

export const landing = createRoute({
  path: '/:locale/landing/:id',
  component: loadable(() =>
    import(/* webpackChunkName: "landing" */ 'pages/content/landing'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const livesList = createRoute({
  path: '/:locale/events',
  component: loadable(() => import(/* webpackChunkName: "lives-list" */ 'pages/content/lives/list'),
    {
      fallback: loading,
      resolveComponent: (imported, props) => () => {
        return React.createElement(imported.default, { ...props, slug: "events" }, [])
      }
    }
  ),
  exact: true,
})

export const recordingsList = createRoute({
  path: '/:locale/events/recordings',
  component: loadable(() => import(/* webpackChunkName: "lives-list" */ 'pages/content/lives/list'),
    {
      fallback: loading,
      resolveComponent: (imported, props) => () => {
        let Component = imported.default
        return <Component {...props} slug="recordings" />
      }
    },
  ),
  exact: true,
})

export const myEventsList = createRoute({
  path: '/:locale/events/my-events',
  component: loadable(() =>
    import(
        /* webpackChunkName: "lives-my-events" */ 'pages/content/lives/my-events'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const archivedLivesList = createRoute({
  path: '/:locale/events/archived-events',
  component: loadable(() =>
    import(
        /* webpackChunkName: "lives-archived-events" */ 'pages/content/lives/archived-events'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const livesView = createRoute({
  path: '/:locale/events/:id',
  component: loadable(() =>
    import(/* webpackChunkName: "lives-view" */ 'pages/content/lives/view'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const listsView = createRoute({
  path: '/:locale/lists/:id',
  component: loadable(() =>
    import(/* webpackChunkName: "lists-view" */ 'pages/content/lists/view'),
    {
      fallback: loading
    }
  ),
})

export const videosList = createRoute({
  path: '/:locale/videos',
  component: loadable(() =>
    import(/* webpackChunkName: "videos-list" */ 'pages/content/videos/list'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const videosFilter = createRoute({
  path: '/:locale/videos/:filter/:name',
  component: videosList.component,
  exact: true,
})

export const videoView = createRoute({
  path: '/:locale/videos/:id',
  component: loadable(() =>
    import(/* webpackChunkName: "videos-view" */ 'pages/content/videos/view'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const notFoundLive = createRoute({
  component: loadable(() =>
    import(
        /* webpackChunkName: "not-found" */ 'pages/content/lives/not-found'
    ),
    {
      fallback: loading
    }
  ),
})