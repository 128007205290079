import React from 'react'
import { Link } from 'components/link'

export default function LinkA(props) {
  const { with_international_mix: mix } = props.searchparams || {}
  const search = mix ? `?with_international_mix=${mix}` : ''

  if (props.link.match(/^https/)) {
    return <a href={props.link + search} {...props} />
  } else {
    return <Link to={props.link + search} {...props} />
  }
}
