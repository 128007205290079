import * as C from './consts'
import { getListMyEvents } from './actions'
import { defineNotFoundPage, restoreNotFoundPage } from 'modules/common/actions'
import { livesView } from 'config/routes'

function updateEventInList(list, data) {
  return list.map(item => {
    if (item.slug === data.slug) {
      return {
        ...item,
        ...data,
      }
    } else {
      return item
    }
  })
}

export default ({ getState, dispatch }) => {
  return next => action => {
    const state = getState()
    switch (action.type) {
      case C.GET_LIVE.FAILURE: {
        const path = livesView.getPath(state.common.locale, action.id)
        dispatch(defineNotFoundPage(path))
        break
      }
      case C.FOLLOW_LIVE.REQUEST: {
        const id = action.event.slug
        const { list, byID } = getState().lives
        dispatch({
          type: C.GET_LIST.SUCCESS,
          data: updateEventInList(list.payload, action.event),
        })
        if (byID.hasOwnProperty(id)) {
          dispatch({
            type: C.GET_LIVE.SUCCESS,
            id: id,
            data: {
              ...byID[id].payload,
              subscribed: action.event.subscribed,
            },
          })
        }
        break
      }
      case C.GET_LIVE.SUCCESS: {
        const path = livesView.getPath(state.common.locale, action.id)
        dispatch(restoreNotFoundPage(path))
        break
      }
      case C.FOLLOW_LIVE.SUCCESS: {
        dispatch(getListMyEvents())
        break
      }
      default: {
        return next(action)
      }
    }
    return next(action)
  }
}
