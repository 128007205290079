import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { FSModal } from 'components/modal'

import Form from 'common/components/form/new-form'
import { Submit, Button } from 'components/form/button'

class TosInfo extends Component {
  static defaultProps = {
    close() {},
    confirm() {},
  }

  onSubmit = ev => {
    this.props.actions.tos.confirmToS().then(() => {
      this.props.confirm()
    })
  }

  render() {
    const strings = this.props.common.strings
    const tosInfo = this.props.tos.tosInfo.payload

    return (
      <FSModal closeButton={false}>
        <div className="fsp-wrap fsp-wrap--wide">
          <div className="fsp-text">
            <span className="fsp__title">
              <div class="header-inner">
                <a href="/" class="header-logo">
                  <span class="header-logo__img header-logo__img--black"></span>
                </a>
              </div>
              {strings['page.infoTerms.title']}
            </span>
            <div className="fsp__text">
              <h4
                dangerouslySetInnerHTML={{
                  __html: tosInfo.popup_content,
                }}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: tosInfo.content,
                }}
              />
            </div>
            <Form onSubmit={this.onSubmit}>
              <div className="fsp-buttons _fixed">
                <div className="fsp-buttons-inner fsp-buttons-inner--wide">
                  <div className="fsp-button">
                    <Button
                      onClick={this.props.close}
                      className="button button--black"
                    >
                      {strings['page.agreeTerms.reject']}
                    </Button>
                  </div>
                  <div className="fsp-button">
                    <Submit className="button button--free">
                      {strings['page.agreeTerms.submit']}
                    </Submit>
                  </div>
                </div>
                <div className="fsp-buttons-inner fsp-buttons-inner--wide">
                  <p className="fsp__info">{strings['page.agreeTerms.info']}</p>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </FSModal>
    )
  }
}

export default DI(['tos'])(TosInfo)
