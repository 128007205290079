// eslint-disable-next-line
import React from 'react'
import { DI } from 'modules'

import FieldsValidator from 'react-fields-validator'

export class Validator extends FieldsValidator {
  isRequiredError = this.props.common.strings['isRequiredError']
  isTextError = this.props.common.strings['isTextError']
  isNumberError = this.props.common.strings['isNumberError']
  isUrlPathError = this.props.common.strings['isUrlPathError']
  isUrlError = this.props.common.strings['isUrlError']
  isEmailError = this.props.common.strings['isEmailError']
  isPasswordError = this.props.common.strings['isPasswordError']
  isLatinError = this.props.common.strings['isLatinError']
  isLatinAndCyrillicError = this.props.common.strings['isLatinAndCyrillicError']
  isPhoneError = this.props.common.strings['isPhoneError']
  isLettersAndDigitsError = this.props.common.strings['isLettersAndDigitsError']
  isLettersAndDigitsWithCyrillicError =
    this.props.common.strings['isLettersAndDigitsWithCyrillicError']
  isLatinCharactersOnlyError =
    this.props.common.strings['isLatinCharactersOnlyError']
  isLatinAndCyrillicCharactersOnlyError =
    this.props.common.strings['isLatinAndCyrillicCharactersOnlyError']
  isIPError = this.props.common.strings['isIPError']
  isRtmpURLError = this.props.common.strings['isRtmpURLError']
  isSrtURLError = this.props.common.strings['isSrtURLError']

  isLettersAndDigits(value) {
    return /^[a-zA-Z0-9\s-]+$/.test(value)
  }

  isLettersAndDigitsWithCyrillic(value) {
    return /^[a-zA-Za-åa-ö-w-я0-9\s-]+$/.test(value)
  }

  isLatinCharactersOnly = value => {
    return /^([A-Za-z\s\d./\\()\"':,.;<>~!@#$%^&*|+=\[\]{}`~?\-«»]*)$/.test(
      value
    )
  }

  isLatinAndCyrillicCharactersOnly = value => {
    return /^([a-zA-Za-åa-ö-w-я\s\d./\\()\"':,.;<>~!@#$%^&*|+=\[\]{}`~?\-«»]*)$/.test(
      value
    )
  }

  isComplexError = this.props.common.strings['password_not_complex']

  isComplex(value) {
    return /[A-Za-z]/.test(value) && /\d/.test(value) && /^.{8,}$/.test(value)
  }

  isPhone(value) {
    // eslint-disable-next-line
    return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)
  }

  confirm(value, ...params) {
    const isValid = params.length ? value === params[0] : true
    return isValid
  }

  isPassword(value) {
    return /[A-Za-z]/.test(value) && /\d/.test(value) && /^.{8,}$/.test(value)
  }

  isRtmpURL(value) {
    value = String(value)
    var reg = /^rtmp(s)?:\/\/.+$/gim
    return value.match(reg)
  }

  isLatin(value) {
    return /^[a-zA-Z0-9\s#&_\/\\|\.\-`\—']+$/.test(value)
  }

  isLatinAndCyrillic(value) {
    return /^[a-zA-Za-åa-ö-w-я0-9\s#&_\/\\|\.\-`\—']+$/.test(value)
  }

  isIP(value) {
    return /^(\d{1,3}\.){3}\d{1,3}$/gi.test(value)
  }

  isSrtURL(value) {
    return /^srt:\/\/[a-zA-Z0-9.-_]+$/gim.test(value)
  }

  isEmail(value) {
    return /^[\d\w]+[\w\d._+-]*@([\w\d_-]+\.)+[\w]{2,}$/i.test(value)
  }
}

export default DI()(Validator)
