import { getCurrentUser } from 'modules/user/actions'
import { updateVAT } from './actions'
import * as C from './consts'

export default function ({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      const { isSignedIn } = getState().user
      if (
        action.type === C.VALIDATE_TAXES.SUCCESS &&
        action.data.valid &&
        isSignedIn
      ) {
        dispatch(updateVAT(action.body.vat_id))
      }

      if (action.type === C.UPDATE_VAT.SUCCESS && isSignedIn) {
        dispatch(getCurrentUser()).catch(() => {})
      }

      return next(action)
    }
  }
}
