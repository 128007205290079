import React from 'react'
import { isServer } from 'store'

let Consumer

if (!isServer) {
  Consumer = require('react-actioncable-provider').ActionCableConsumer
}

export function ActionCableConsumer(props) {
  if (Consumer) {
    return <Consumer {...props} />
  } else {
    return props.children || null
  }
}
