import * as C from './consts'
// import { combineReducers } from "redux";
import { boundApiReducer as api } from 'redux-upgrader'

export const VATValidate = api(C.VALIDATE_TAXES, Object)

export const prices = api(C.GET_PRICES, Object, null, ({ data }) => {
  const price = Number(data.price)
  const tax = Number(data.tax)
  const pure_price = +((price / (100 + tax)) * 100).toFixed(2)
  const tax_value = Math.ceil(price * 100 - pure_price * 100) / 100

  return {
    price: Math.ceil(price * 100) / 100,
    pure_price,
    tax,
    tax_value,
  }
})
