import superagent from 'superagent'
import * as config from 'config'
import { ApiAction } from 'redux-upgrader'

import { isServer } from 'store'
import { getLocale } from 'middlewares/localizer'

export const get = (...args) => api('GET', ...args)
export const post = (...args) => api('POST', ...args)
export const put = (...args) => api('PUT', ...args)
export const del = (...args) => api('DELETE', ...args)
export const patch = (...args) => api('PATCH', ...args)

get.makeQueryString = makeQueryString

export default function api(
  method,
  actions,
  dispatch,
  getState,
  url,
  data = {},
  ...otherArgs
) {
  if (!actions instanceof Array) {
    actions = new ApiAction(String(actions))
  }

  dispatch({ type: actions.REQUEST, params: otherArgs || [] })

  return sendRequest({ method, url, data })
    .then(requestSuccess(actions, dispatch, ...otherArgs))
    .catch(requestFailure(actions, dispatch, ...otherArgs))
}

export function sendRequest({ method, url, data = {}, publicAsEntry = false }) {
  const locale = getLocale()
  let body = data
  let updURL = url

  if (!url.match(/^http/) && !publicAsEntry) {
    updURL = config.API_HOST + url
  }

  if (method === 'GET') {
    body = makePlainObject(body)
    updURL = makeQueryString({ ...body, locale }, updURL)
  } else {
    updURL = makeQueryString({ locale }, updURL)
  }

  return request(method, updURL, { credentials: true, body })
}

function request(method, url, options = {}) {
  return new Promise(function (resolve, reject) {
    let sa = superagent(method, url)

    if (options.credentials) {
      sa = sa.withCredentials()
    }

    // sa = sa.type('application/json')
    // sa = sa.set('Accept', 'application/json, text/plain, */*')
    // sa = sa.set('Content-Type', 'multipart/form-data');
    // sa = sa.set('Content-Type', 'application/x-www-form-urlencoded');
    // sa = sa.set('Access-Control-Allow-Origin', '*');
    // sa = sa.set('Access-Control-Allow-Headers', 'Origin, X-API-Token');
    sa = sa.send(options.body)
    sa = sa.end(function (err, res) {
      if (err || !res.ok) {
        reject(err)
      } else {
        resolve(res)
      }
    })
    return sa
  })
}

function requestSuccess(acts, disp, ...actionParams) {
  return response => {
    disp({
      type: acts.SUCCESS,
      data: prepareData(response.body),
      params: actionParams || [],
      response: response,
    })
    return response
  }
}

function requestFailure(acts, disp, ...actionParams) {
  return error => {
    disp({
      type: acts.FAILURE,
      params: actionParams || [],
      error: error,
    })
    // if (error && error.response && error.response.body && error.response.body.errors) {
    //     if (
    //         error.response.error.method === 'POST' ||
    //         error.response.error.method === 'PATCH'
    //     ) {
    //         error.response.body.errors.forEach(({ code }) => {
    //             disp(pushFlashError('Error', { code }));
    //         });
    //     }
    // }

    throw error
  }
}

function prepareData(body) {
  return body
}

export function makeQueryString(values, def = '', withoutSession) {
  let [path = '', params = ''] = def.split('?')
  let queryString = params ? params.split('&') : []

  for (let key in values) {
    let item = values[key]
    let param = Array.isArray(item)
      ? item
          .map(
            value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&')
      : `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
    queryString.push(param)
  }
  return queryString.length ? path + '?' + queryString.join('&') : path
}

export function makePlainObject(treeObject, _prefix) {
  let result = {}
  for (let key in treeObject) {
    const isArray = treeObject[key] instanceof Array
    const isObject =
      typeof treeObject[key] === 'object' && treeObject[key] !== null
    const isFile = isServer
      ? false
      : treeObject[key] instanceof File || treeObject[key] instanceof Blob

    let _key = _prefix ? `${_prefix}[${key}]` : key

    if (isArray) {
      _key += '[]'
      result[_key] = treeObject[key]
    } else if (isObject && !isFile) {
      Object.assign(result, makePlainObject(treeObject[key], _key))
    } else {
      result[_key] = treeObject[key]
    }
  }
  return result
}

export function makeFormData(object) {
  let fd = new FormData()

  for (let key in object) {
    const updKey = key //.replace(/\[\d+\]/g, '[]');
    const value = object[key]
    const isArray = value instanceof Array

    const isFile = isServer
      ? false
      : value instanceof File || value instanceof Blob
    const isObject = typeof value === 'object' && value !== null

    if (isArray) {
      value.forEach(el => {
        fd.append(updKey, el)
      })
    } else if (isFile) {
      fd.append(updKey, value, value.name)
    } else if (isObject) {
      fd.append(updKey, JSON.parse(value))
    } else {
      fd.append(updKey, value)
    }
  }
  return fd
}
