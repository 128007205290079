import * as C from '../consts'
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'

export const planningList = boundReducer([], {
  [C.GET_PLANNING_SECTION.SUCCESS]: (state, action) => {
    const splitText = action.data.content.split('<h1>')
    const contentConf = []

    splitText.forEach(content => {
      if (content) {
        const captionEndIndex = content.indexOf('</h1>')
        captionEndIndex !== -1 &&
          contentConf.push({
            caption: content.substring(0, captionEndIndex),
            body: content.substring(captionEndIndex),
          })
      }
    })

    return contentConf
  },
})
export const planningAccess = api(C.CHECK_PLANNING_SECTION_ACCESS, Object)
