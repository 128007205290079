import React from 'react'
import PageLoading from 'components/page-loading'
// import loadable from 'react-loadable'
import loadable from '@loadable/component'

import { createRoute } from 'utils/routing'
import { IS_LANDING } from 'config'

const loading = () => <PageLoading />

/* Static  */

export const choosePlan = createRoute({
  path: '/:locale/plans',
  component: loadable(
    () =>
      import(/* webpackChunkName: "choose-plan" */ 'pages/static/choose-plan'),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

export const impressum = createRoute({
  path: '/:locale/impressum',
  component: loadable(
    () =>
      import(/* webpackChunkName: "choose-plan" */ 'pages/static/impressum'),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

// RPTL - 94
// export const broadcast = createRoute({
//     path: '/:locale/broadcast-services',
//     component: loadable({
//         loader: () =>
//             import(
//                 /* webpackChunkName: "broadcast-services" */ 'pages/static/broadcast'
//             ),
//               {
// fallback: loading
//    }
//     }),
//     exact: true,
// });

// export const broadcastLocation = createRoute({
//     path: '/:locale/broadcast-services/:location',
//     component: loadable({
//         loader: () =>
//             import(
//                 /* webpackChunkName: "broadcast-services-locale" */ 'pages/static/broadcast/location'
//             ),
//               {
// fallback: loading
//     }
//     }),
//     exact: true,
// });

export const about = createRoute({
  path: '/:locale/about',
  component: loadable(
    () => import(/* webpackChunkName: "about" */ 'pages/static/about'),
    {
      fallback: loading,
    }
  ),
  // exact: true
})

export const tos = createRoute({
  path: '/:locale/terms-of-service',
  component: loadable(
    () => import(/* webpackChunkName: "tos" */ 'pages/static/terms-of-service'),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

export const privacyPolicy = createRoute({
  path: '/:locale/privacy-policy',
  component: loadable(
    () =>
      import(
        /* webpackChunkName: "privacy-policy" */ 'pages/static/privacy-policy'
      ),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

export const cookiePolicy = createRoute({
  path: '/:locale/cookie-policy',
  component: loadable(
    () =>
      import(
        /* webpackChunkName: "cookie-policy" */ 'pages/static/cookie-policy'
      ),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

export const careersList = createRoute({
  path: '/:locale/career',
  component: loadable(
    () =>
      import(
        /* webpackChunkName: "careers-list" */ 'pages/static/careers/careers-list'
      ),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

export const careersView = createRoute({
  path: '/:locale/career/:id',
  component: loadable(
    () =>
      import(
        /* webpackChunkName: "careers-view" */ 'pages/static/careers/careers-view'
      ),
    {
      fallback: loading,
    }
  ),
  exact: true,
})

export const oldbrowsersView = createRoute({
  path: '/:locale/old-browsers',
  component: loadable(
    () =>
      import(/* webpackChunkName: "old-browsers" */ 'pages/static/oldbrowsers'),
    {
      fallback: loading,
    }
  ),
  exact: true,
})
