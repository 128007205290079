import Form from 'common/components/form/new-form'
import { Submit } from 'components/form/button'
import Checkbox from 'components/form/checkbox'
import { Modal } from 'components/modal'
import Cookies from 'js-cookie'
import { DI } from 'modules'
import React, { Component, Fragment } from 'react'

const COOKIE_KEY = 'newsletter_subscription'

export class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.state = this.getDefaultState()
  }

  getDefaultState() {
    const userData = this.props.user.currentUser.payload

    return {
      subscribed: true,
      form: {
        user: { ...userData },
      },
    }
  }

  onChange = (ev, form) => {
    this.setState({
      subscribed: !this.state.subscribed,
      form: {
        user: {
          ...this.state.form.user,
          ...form.user,
        },
      },
    })
  }

  onSubmit = (ev, form) => {
    this.props.actions.user.updateCurrentUser(form.user).then(resp => {
      this.props.onClose()
      this.props.common.cookiesAccepted.functional &&
        Cookies.set(COOKIE_KEY, true, { expires: 90 })
    })
  }

  render() {
    const strings = this.props.common.strings
    const { user } = this.state.form
    const { onClose } = this.props

    return (
      <Fragment>
        {!Cookies.get(COOKIE_KEY) && (
          <Modal
            onClose={onClose}
            classNameWrap=""
            popupClassName="popup popup--land"
            closeClassName="popup__close popup__close--land"
          >
            <div className="popup-sign-in">
              <span className="sign-in__title">
                {strings['newsletters_settings.title']}
              </span>
              <div className="sign-in-form">
                <Form onChange={this.onChange} onSubmit={this.onSubmit}>
                  <Checkbox
                    name="user[newsletter_notify]"
                    checked={this.state.subscribed}
                    uncheckedValue={false}
                  >
                    {strings['newsletters_settings.label']}
                  </Checkbox>
                  <div className="field field--submit">
                    <Submit
                      className="button button--free"
                      //loadingText={strings['page.profile.form.loading']}
                    >
                      {strings['page.agreeTerms.submit']}
                    </Submit>
                  </div>
                </Form>
              </div>
            </div>
          </Modal>
        )}
      </Fragment>
    )
  }
}

export default DI(['user', 'locales'])(Newsletter)
