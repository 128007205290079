import * as Fingerprint2 from 'fingerprintjs2'
import Cookies from 'js-cookie'
import * as config from 'config'

const cookieName = 'guid_token'

const assignHashCookie = () => {
  Fingerprint2.getV18(hash => {
    Cookies.set(cookieName, hash, {
      expires: 365,
      domain: '.' + config.COMMON_DOMAIN,
    })
  })
}

const fingerprint = () => {
  const cookieGuid = Cookies.get(cookieName)

  if (cookieGuid === undefined) {
    if (window.requestIdleCallback) {
      requestIdleCallback(assignHashCookie)
    } else {
      setTimeout(assignHashCookie, 500)
    }
  }
}

export default fingerprint
