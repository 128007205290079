import * as C from '../consts'

/* AGREEMENTS */

export function getAgreements() {
  return {
    types: C.GET_AGREEMENTS,
    url: '/api/current_user/agreements',
  }
}

export function getAgreementsRequests() {
  return {
    types: C.GET_AGREEMENT_REQUESTS,
    url: '/api/current_user/agreement_requests',
  }
}

export function getActiveAgreements(search = {}, isSignedIn = true) {
  return {
    types: C.GET_ACTIVE_AGREEMENTS,
    url: `/api/current_${
      isSignedIn ? 'user' : 'guest'
    }/agreements/available_for_operations`,
    search,
  }
}

export function acceptAgreement(id) {
  return {
    types: C.ACCEPT_AGREEMENT,
    method: 'POST',
    url: `/api/current_user/agreements/${id}/accepts`,
  }
}

export const removeUserAcceptingAgreement = id => (d, g) => {
  return d({
    type: C.REMOVE_USER_ACCEPTING_AGREEMENTS,
    data: g().user.userAcceptingAgreements.filter(
      agreement => id !== agreement.id
    ),
  })
}

export function getAgreementRequestData() {
  return {
    types: C.GET_AGREEMENT_REQUEST_DATA,
    url: '/api/agreement_requests/new',
  }
}

export function agreementRequest(body) {
  return {
    types: C.AGREEMENT_REQUEST,
    method: 'POST',
    url: '/api/agreement_requests',
    body: body,
  }
}

export function agreementRequestForUser(body) {
  return {
    types: C.AGREEMENT_REQUEST,
    method: 'POST',
    url: '/api/current_user/agreement_requests',
    body: body,
  }
}

export function getPlan(plan) {
  return {
    types: C.GET_PLAN,
    url: `/api/current_user/payments/plans/${plan}`,
  }
}

export function getPurchasePossibility(agreement_type) {
  return {
    types: C.GET_PURCHASE_POSSIBILITY,
    url: `/api/current_user/agreements/purchase_possibility`,
    search: { agreement_type },
  }
}

export function setAutoRenewal(agreementID, value) {
  const _value = value ? 'opt_ins' : 'opt_outs'

  return {
    method: 'POST',
    types: C.SET_AUTO_RENEWAL,
    url: `/api/current_user/agreements/${agreementID}/${_value}`,
  }
}

/* AGREEMENTS */
