import superagent from 'superagent'
import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { reducers } from './modules'
import localizeMiddleware from 'middlewares/localizer'
import notificationsMiddleware from 'middlewares/notifications'
import apiMiddleware from 'middlewares/api'

import { combineReducers } from 'redux'
import { middlewares as customMiddlewares } from 'modules'
import * as config from 'config'

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement &&
  !window.isServer
)

const apiHost = config.API_HOST

export function createPromise({ method = 'GET', url, body, headers = {} }) {
  return new Promise(function (resolve, reject) {
    let sa = superagent(method, url).set(headers).withCredentials()

    return sa.send(body).end(function (err, res) {
      if (err || !res.ok) {
        reject({ ...err })
      } else {
        resolve(res)
      }
    })
  })
}

export default (url = '/', newrelic) => {
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory()

  const enhancers = []

  if (/*process.env.PRJ_ENV !== 'PRODUCTION' &&*/ !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  let middleware = [
    apiMiddleware({ createPromise, apiHost }),
    thunk,
    routerMiddleware(history),
    localizeMiddleware,
    notificationsMiddleware,
    ...customMiddlewares,
  ]

  const errorLoggerMiddleware = () => {
    return next => action => {
      if (
        action &&
        action.type &&
        typeof action.type.indexOf === 'function' &&
        action.type.indexOf('FAILURE') !== -1
      ) {
        const timestamp = Date.now()
        newrelic.recordLogEvent({
          message: `Store error. ${action.type}`,
          level: 'error',
          timestamp,
          error: action.error,
        })
      }

      next(action)
    }
  }

  if (newrelic && isServer) {
    middleware.push(errorLoggerMiddleware)
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  let initialState = {}

  if (!isServer) {
    initialState = window._frontloadData
    let script = document.getElementById('state_script')

    if (script) {
      script.remove()
    }
  }

  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      ...reducers,
    }),
    initialState,
    composedEnhancers
  )

  return {
    store,
    history,
  }
}
