import React from 'react'
import { isServer } from 'store'
import { isProd } from 'config'

import WebPushModal from './web-push-modal'
import { DI } from '../../modules'

let messaging

if (!isServer) {
  try {
    messaging = require('utils/init-fcm').messaging
  } catch (err) {
    // console.error(err);
  }
}

class WebPush extends React.Component {
  componentDidMount() {
    this.timeout = setTimeout(() => this.requestPermittions(), 5000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  requestPermittions() {
    if (messaging) {
      messaging
        .requestPermission()
        .then(async () => {
          const token = await messaging.getToken()
          this.props.actions['web-push']
            .getPushSettings(token)
            .catch(async err => {
              await this.props.actions['web-push'].setPushSettings(token, {
                notification_locales: [this.props.common.locale],
              })
              await this.props.actions['web-push'].getPushSettings(token)

              this.props.actions['web-push'].toggleWebpushSettingsPopup()
            })
        })
        .catch(function (err) {
          if (!isProd) {
            console.log('Unable to get permission to notify.', err)
          }
        })
      //event on message from firebase server
      navigator.serviceWorker.addEventListener('message', message => {
        if (!isProd) {
          console.log(message)
        }
      })
    }
  }

  render() {
    if (this.props.common.toggleWebpushSettingsPopup) {
      return <WebPushModal />
    } else {
      return null
    }
  }
}

export default DI(['locales', 'web-push'])(WebPush)
