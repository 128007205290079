import * as C from './consts'
import * as M from './models'
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'

// SINGLE VIDEO

export const byID = api(
  C.GET_VIDEO,
  M.Video,
  action => action.id,
  action => action.data
)

// LIST OF VIDEOS

export const byPage = api(
  C.GET_LIST,
  Array,
  action => action.search.page,
  action => action.data.videos
)

export const related = api(C.GET_RELATED_LIST, M.RelatedVideos)

export const searchKey = api(C.GET_LIST, Object, null, action => {
  const { search_key } = action.data
  if (search_key) {
    return { search_key }
  } else {
    return {}
  }
})

export const count = boundReducer(0, {
  [C.GET_LIST.SUCCESS]: (state, action) => action.data.count,
})

export const rangeDuration = api(C.GET_DURATION, M.Duration)

export const localesResults = boundReducer([], {
  [C.GET_LIST.REQUEST]: (state, action) => [],
  [C.GET_LIST.SUCCESS]: (state, action) => action.data.locales_result,
  [C.GET_LIST.FAILURE]: (state, action) => [],
})

export const suggestsBySearch = api(
  C.GET_SUGGESTS,
  M.Videos,
  action => action.search.search
)

export const availableFormats = api(C.GET_AVAILABLE_FORMATS, M.AvailableFormats)

export const paymentAlert = boundReducer(0, {
  [C.SET_PAYMENT_ALERT]: (state, action) => action.alert,
})
