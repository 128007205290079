import { createNotification } from 'modules/notifications/actions'
import { UPDATE_VAT } from 'modules/taxes/consts'
import {
  INVITE_MEMBER,
  PP_SUBSCRIPTIONS_PAY,
  PP_SUBSCRIPTIONS_PROCEED,
  SIGN_IN,
  UPDATE_BILLING_INFO,
  UPDATE_CARD,
  UPDATE_CURRENT_USER,
} from 'modules/user/consts'

import { SET_LOCALE } from 'modules/common/consts'

export default ({ getState, dispatch }) => {
  const { user } = getState()

  function success(key, options) {
    const { common } = getState()
    return {
      type: 'success',
      children: common.strings[key],
      ...options,
    }
  }

  function error(key, options) {
    const { common } = getState()
    return {
      type: 'error',
      children: common.strings[key],
      ...options,
    }
  }

  return next => action => {
    function checkAction() {
      switch (action.type) {
        case SIGN_IN.SUCCESS: {
          return success('alert.flash.signin')
        }
        case UPDATE_CURRENT_USER.SUCCESS: {
          return [
            success('alert.flash.profile_updated'),
            action.body.user.password && success('alert.flash.password.update'),
          ]
        }
        case INVITE_MEMBER.SUCCESS: {
          return success('alert.flash.invitation.send')
        }
        case UPDATE_CARD.SUCCESS: {
          return success('alert.flash.payment.card.update')
        }
        case UPDATE_BILLING_INFO.SUCCESS: {
          return success('alert.flash.billing_updated')
        }
        case UPDATE_VAT.SUCCESS: {
          if (!user.isSignedIn) return null
          return success('alert.flash.vat_updated')
        }
        case PP_SUBSCRIPTIONS_PAY.FAILURE:
        case PP_SUBSCRIPTIONS_PROCEED.FAILURE: {
          const body = action.error.response.body || {}
          const { errors = [] } = body

          if (error.length) {
            return errors.map(err =>
              dispatch({
                type: 'error',
                children: err instanceof Object ? err.message : err,
                timeout: 5000,
              })
            )
          } else {
            return dispatch({
              type: 'error',
              children: 'Error',
              timeout: 5000,
            })
          }
        }
        case PP_SUBSCRIPTIONS_PAY.SUCCESS: {
          return success('page.payment.success.title')
        }
        default:
          return []
      }
    }
    const notifications = [].concat(checkAction()).filter(Boolean)

    if (notifications.length) {
      notifications.map(notification =>
        dispatch(createNotification(notification))
      )
    }

    return next(action)
  }
}
