import * as C from './consts'
import { humanize } from 'helpers/strings'
import { boundApiReducer as api } from 'redux-upgrader'

export const list = api(C.GET_TARGETS, Object, action => action.slug)
export const providers = api(C.GET_PROVIDERS, Array, null, action =>
  action.data.map(item => ({
    title: humanize(item),
    value: item,
  }))
)
