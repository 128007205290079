import React from 'react'
import Component from 'components/component'

class Base extends Component {
  get className() {
    return this.classList(
      `${this.props.defaultClassName}`,
      this.props.big && `${this.props.defaultClassName}--big`,
      this.props.smallGreen && `${this.props.defaultClassName}--small-green`,
      this.props.smallRed && `${this.props.defaultClassName}--small-red`,
      this.props.loading && '_waiting',
      this.props.className
    )
  }

  render() {
    return (
      <button
        type={this.props.type}
        className={this.className}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.children}
        {this.props.loadingText && (
          <span className="button__loading">{this.props.loadingText}</span>
        )}
      </button>
    )
  }
}

export class Button extends Base {
  static defaultProps = {
    type: 'button',
    defaultClassName: 'button',
  }
}

export class Submit extends Base {
  static defaultProps = {
    type: 'submit',
    defaultClassName: 'button',
  }
}

export class TextButton extends Base {
  static defaultProps = {
    type: 'button',
    defaultClassName: 'text-button',
  }
}
