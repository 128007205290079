import React from 'react'
import Component from 'components/component'

import modal from './base'

class FSModal extends Component {
  static defaultProps = {
    closeButton: true,
  }
  render() {
    const className = this.classList('fsp', this.props.black && 'fsp--black')

    return (
      <>
        <div className={className}>
          {this.props.closeButton && (
            <button
              type="button"
              className="fsp__close"
              onClick={this.props.close}
            ></button>
          )}
          {this.props.children}
        </div>
      </>
    )
  }
}

export default modal(FSModal)
