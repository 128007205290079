import React, { Fragment } from 'react'
import { DI } from 'modules'
import { IS_LANDING } from '../../config'

function FooterBots({ actions, common, ...webpushProps }) {
  const onToggleModal = ev => {
    ev.preventDefault()
    actions['web-push'].toggleWebpushSettingsPopup()
  }

  const { strings } = common
  const { isInited } = webpushProps['web-push'].pushSettings
  const footer = strings[IS_LANDING ? 'footer.landing.dashboard' : 'footer.dashboard'] || {}
  const bots = footer.bots || []

  return (
    <div className="footer-bots">
      {bots.map((item, index) => (
        <Fragment key={index}>
          {(item.ico !== 'push' || isInited) && (
            <div className="footer-bots__item">
              <a
                href={'https://' + item.link}
                className={`footer-bots-item footer-bots-item--${item.ico}`}
                onClick={item.ico === 'push' ? onToggleModal : void 0}
                target="_blank"
                rel="noreferrer"
              >
                <span className="footer-bots-item__title">{item.name}</span>
                <span className="footer-bots-item__link">
                  {item.link || item.subtitle}
                </span>
              </a>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default DI(['common', 'web-push'])(FooterBots)
