import React, { Fragment } from 'react'
import Component from 'components/component'
import Validator from 'components/validator'

const className = (hasError, props = {}) =>
  Component.classList(
    'input',
    hasError && '_error',
    props.width && `input--${props.width}`,
    props.tabletWidth && `input--tablet-${props.tabletWidth}`
  )

class Base extends Component {
  static defaultProps = {
    isRequired: false,
    isLatin: false,
    isPassword: false,
    isEmail: false,
    isPhone: false,
    isIP: false,
    validators: [],
    type: 'text',
    disabled: false,
    autoComplete: '',
    value: '',
    width: null,
    tabletWidth: null,
    onChange() {},
  }

  get validators() {
    return [
      this.props.isRequired && 'isRequired',
      this.props.isLatin && 'isLatin',
      this.props.isLatinAndCyrillic && 'isLatinAndCyrillic',
      this.props.isPassword && 'isPassword',
      this.props.isEmail && 'isEmail',
      this.props.isPhone && 'isPhone',
      this.props.isUrl && 'isUrl',
      this.props.isLettersAndDigits && 'isLettersAndDigits',
      this.props.isLettersAndDigitsWithCyrillic &&
        'isLettersAndDigitsWithCyrillic',
      this.props.isLatinCharactersOnly && 'isLatinCharactersOnly',
      this.props.isLatinAndCyrillicCharactersOnly &&
        'isLatinAndCyrillicCharactersOnly',
      this.props.isComplex && 'isComplex',
      this.props.isIP && 'isIP',
      ...this.props.validators,
    ].filter(Boolean)
  }
}

export class TextInput extends Base {
  render() {
    return (
      <Validator
        onValidateForm={this.props.onValidateForm}
        value={this.props.value || ''}
        validators={this.validators}
        render={({ value, hasError, isValid, messages }) => {
          let showError = this.props.reactOnChange ? !isValid : hasError
          return (
            <Fragment>
              <input
                type={this.props.type}
                className={className(showError, this.props)}
                name={this.props.name}
                value={value}
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                placeholder={this.props.placeholder}
                maxLength={this.props.maxLength}
              />
              {showError && <div className="error">{messages[0]}</div>}
            </Fragment>
          )
        }}
      />
    )
  }
}

export class TextInputField extends Base {
  state = {
    focus: false,
  }
  showFocus = focus => () =>
    this.setState({
      focus: focus,
    })
  render() {
    const { disabled, autoComplete, onValidate } = this.props
    return (
      <Validator
        onValidateForm={this.props.onValidateForm}
        onValidate={({ isValid }) => onValidate?.(isValid)}
        value={this.props.value}
        validators={disabled ? [] : this.validators}
        render={({ value, hasError, isValid, messages }) => (
          <Fragment>
            <div className="field">
              <input
                onBlur={this.showFocus(false)}
                onFocus={this.showFocus(true)}
                onChange={this.props.onChange}
                type={this.props.type}
                disabled={disabled}
                placeholder={this.props.title}
                className={className(!disabled && hasError)}
                name={this.props.name}
                value={value}
                autoComplete={autoComplete}
              />
              {!disabled &&
                this.props.ok &&
                value &&
                !this.state.focus &&
                isValid && <span className="_ok"></span>}
            </div>
            {!disabled && hasError && (
              <div className="error">{messages[0]}</div>
            )}
          </Fragment>
        )}
      />
    )
  }
}
