import * as api from 'utils/api'
import * as C from './consts'

export function getLive(id) {
  return {
    types: C.GET_LIVE,
    id,
    url: `/api/content/live/events/${id}`,
  }
}

export function getList(page = 1, per_page = 12) {
  return {
    types: C.GET_LIST,
    url: '/api/content/live/events',
    search: { page, per_page },
  }
}

export function getListRecordings(page = 1, per_page = 12) {
  return {
    types: C.GET_LIST_RECORDINGS,
    url: '/api/content/live/recordings',
    search: { page, per_page },
  }
}

export function getListMyEvents() {
  return {
    types: C.GET_LIST_MY_EVENTS,
    url: '/api/content/live/current_user/my_events',
  }
}

export function getListEventsAvailableForCut() {
  return {
    types: C.GET_LIST_ARCHIVED_EVENTS,
    url: '/api/content/live/events/available_for_cut',
  }
}

export function changeSubscription(slug, subscribed) {
  return {
    types: C.FOLLOW_LIVE,
    method: subscribed ? 'POST' : 'DELETE',
    url: `/api/content/live/events/${slug}/subscription`,
    event: {
      slug,
      subscribed,
    },
  }
}

export const unfollowAllLive = () => (d, g) => {
  return api
    .del(
      C.UNFOLLOW_ALL_LIVE,
      d,
      g,
      `/api/content/live/events/subscriptions/destroy_all`
    )
    .then(resp => {
      d(getList())
      return d(getListMyEvents())
    })
}

export const loadLivePage = id => (d, g) => {
  return d(getLive(id))
}

export const bookLive =
  (slug, booking = {}) =>
  (d, g) => {
    return api
      .post(C.BOOK_LIVE, d, g, `/api/content/live/events/${slug}/bookings`, {
        booking,
      })
      .then(resp => {
        d(getLive(slug))
        return resp
      })
  }

export const recordingLive =
  (slug, book_recording = {}) =>
  (d, g) => {
    return api
      .post(
        C.RECORDING_LIVE,
        d,
        g,
        `/api/content/live/recordings/${slug}/embed`,
        {
          book_recording,
        }
      )
      .then(resp => {
        d(getLive(slug))
        return resp
      })
  }

export const wsStatusChangeList = data => (dispatch, getState) => {
  const lives = getState().lives.list.payload
  const changedItem = (lives || []).find(item => item.id === data.id)

  if (changedItem) {
    if (
      data.status !== 'terminated' &&
      data.status !== 'canceled' &&
      data.visible
    ) {
      data.status === 'live'
        ? dispatch({
            type: C.GET_LIST.SUCCESS,
            data: [data, ...lives.filter(item => item !== changedItem)],
          })
        : dispatch({
            type: C.GET_LIST.SUCCESS,
            data: lives
              .filter(item => item !== changedItem)
              .concat({ ...changedItem, ...data }),
          })
    } else {
      dispatch({
        type: C.GET_LIST.SUCCESS,
        data: lives.filter(item => item !== changedItem),
      })
    }
  } else if (data.visible) {
    dispatch({
      type: C.GET_LIST.SUCCESS,
      data: (lives || []).concat(data),
    })
  }
}

export const wsStatusChangeView = data => (dispatch, getState) => {
  const live = getState().lives.byID.getValue(data.slug).payload
  const newLive = { ...live, ...data }
  dispatch({
    type: C.GET_LIVE.SUCCESS,
    data: newLive,
    id: data.slug,
  })
}

export const wsStatusChangeMyEvents = data => (dispatch, getState) => {
  const myEvents = getState().lives.listMyEvents.payload
  const changedBookedItem = (myEvents.booked_events || []).find(
    item => item.id === data.id
  )
  const changedFollowedItem = (myEvents.followed_events || []).find(
    item => item.id === data.id
  )

  if (changedBookedItem || changedFollowedItem) {
    if (
      data.status !== 'terminated' &&
      data.status !== 'canceled' &&
      data.visible
    ) {
      dispatch({
        type: C.GET_LIST_MY_EVENTS.SUCCESS,
        data: {
          ...myEvents,
          ...(changedBookedItem
            ? {
                booked_events: (myEvents.booked_events || [])
                  .filter(item => item !== changedBookedItem)
                  .concat({ ...changedBookedItem, ...data }),
              }
            : {}),
          ...(changedFollowedItem
            ? {
                followed_events: (myEvents.followed_events || [])
                  .filter(item => item !== changedFollowedItem)
                  .concat({ ...changedFollowedItem, ...data }),
              }
            : {}),
        },
      })
    } else {
      dispatch({
        type: C.GET_LIST_MY_EVENTS.SUCCESS,
        data: {
          ...myEvents,
          ...(changedBookedItem
            ? {
                booked_events: (myEvents.booked_events || []).filter(
                  item => item !== changedBookedItem
                ),
              }
            : {}),
          ...(changedFollowedItem
            ? {
                followed_events: (myEvents.followed_events || []).filter(
                  item => item !== changedFollowedItem
                ),
              }
            : {}),
        },
      })
    }
  }
}
