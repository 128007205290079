let hidden, visibilityChange

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const loadScript = async (src, onLoadCallback = () => {}) => {
  let tag = document.createElement('script')
  tag.async = false
  tag.src = src
  tag.onload = onLoadCallback
  const body = document.getElementsByTagName('body')[0]
  body.appendChild(tag)
}

const getScrollbarWidth = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  outer.style.msOverflowStyle = 'scrollbar'

  document.body.appendChild(outer)

  const widthNoScroll = outer.offsetWidth
  outer.style.overflow = 'scroll'

  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  const widthWithScroll = inner.offsetWidth

  outer.parentNode.removeChild(outer)

  return widthNoScroll - widthWithScroll
}

export { hidden, visibilityChange, loadScript, getScrollbarWidth }
