import * as config from 'config'

export function createRoute(config) {
  return {
    ...config,
    getPath() {
      let counter = 0

      return config.path
        .replace(/:\w+/g, (...args) => {
          const argument = arguments[counter++] || ''
          return encodeURIComponent(argument)
        })
        .replace(/\/\//g, '/')
        .replace(/\/$/, '')
    },
    getRawPath() {
      let counter = 0

      return config.path
        .replace(/\([\x21-\x7E]+\)/g, '')
        .replace(/:\w+/g, (...args) => {
          return arguments[counter++] || ''
        })
        .replace(/\/\//g, '/')
        .replace(/\/$/, '')
    },
  }
}
