// import fingerprint from 'utils/fingerprint';
import * as config from 'config'
import ReactGA from 'react-ga'
import fingerprint from 'utils/fingerprint'
import { loadScript } from 'helpers/document'
import { isProd, ruLocaleOnly, IS_LANDING } from 'config'

const YANDEX_ID_VIDEO = 95304815
const YANDEX_ID_TV = 52438093
const YANDEX_ID_LANDING = 96370643

export function gtag() {
  window.dataLayer.push(arguments)
}

export default function (cookiesAccepted) {
  if (cookiesAccepted) {
    const { functional, marketing, performance, strictlyNecessary } =
      cookiesAccepted

    if (strictlyNecessary) {
      initPushServiceWorker()
      initOther()
    }

    if (functional) {
      fingerprint()
      // initVideoTags()
    }

    if (isProd) {
      if (marketing) {
        /*
          Выключено по просьбе Мити 23 08 2022
          initTaboola();
        */
        // initFB()
      }

      if (performance) {
        // initGoogleTagManager()
        // initGA()

        const currentYandexId = IS_LANDING
          ? YANDEX_ID_LANDING
          : ruLocaleOnly
            ? YANDEX_ID_VIDEO
            : YANDEX_ID_TV
        initYM(currentYandexId)
      }
    }
  }
}

function initVideoTags() {
  const path = `${config.STORAGE_HOST}/tags/tags.js`
  loadScript(path, () => {
    try {
      window.plausible =
        window.plausible ||
        function () {
          ;(window.plausible.q = window.plausible.q || []).push(arguments)
        }

      window.VideoTags = new VideoTags()
    } catch (e) {
      if (!isProd) {
        console.log(e)
      }
    }
  })
}

function initGoogleTagManager() {
  loadScript('https://www.googletagmanager.com/gtag/js?id=AW-712740320', () => {
    window.dataLayer = window.dataLayer || []

    gtag('js', new Date())

    gtag('config', 'AW-712740320')
  })
}

function initPushServiceWorker() {
  function getWorkerURL(url) {
    const content = `importScripts( "${url}" );`
    return URL.createObjectURL(new Blob([content], { type: 'text/javascript' }))
  }

  const cross_origin_script_url = `${process.env.PUBLIC_PATH}firebase-messaging-sw.js`
  const worker_url = getWorkerURL(cross_origin_script_url)
  const worker = new Worker(worker_url)
  worker.onmessage = evt => console.log(evt.data)
  URL.revokeObjectURL(worker_url)

  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker
  //     .register(`${process.env.PUBLIC_PATH}firebase-messaging-sw.js`)
  //     .then(function (registration) {
  //       if (!isProd) {
  //         console.log('Registration successful, scope is:', registration.scope)
  //       }
  //     })
  //     .catch(function (err) {
  //       if (!isProd) {
  //         console.log('Service worker registration failed, error:', err)
  //       }
  //     })
  // }
}

function initGA() {
  ReactGA.initialize('UA-125686506-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

function initYM(id) {
  ;(function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        ;(m[i].a = m[i].a || []).push(arguments)
      }
    m[i].l = 1 * new Date()
    for (var j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === r) {
        return
      }
    }
    k = e.createElement(t)
    a = e.getElementsByTagName(t)[0]
    k.async = 1
    k.src = r
    a.parentNode.insertBefore(k, a)
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

  ym(id, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  })
}

function initFB(prod) {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: config.FB_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v3.3',
    })
  }
  ;(function (d, s, id) {
    let js
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js#version=v3.3'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  fbq('init', '1294269254296168')
  fbq('track', 'PageView')
}

function initOther() {
  if (
    /iPad|iPhone|iPod|Macintosh|MacIntel|MacPPC|Mac68K/.test(
      navigator.userAgent
    ) &&
    !window.MSStream
  ) {
    document.body.classList.add('_ios')
  }
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    document.body.classList.add('_android')
  }
  if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    document.body.classList.add('_firefox')
  }
  document.body.classList.add('_loaded')

  document.oncopy = function () {
    if (!window.isAdmin) {
      const bodyElement = document.body
      const selection = window.getSelection()

      if (selection.toString() === window.withoutCopyright) {
        delete window.withoutCopyright
      } else {
        const range = selection.getRangeAt(0)
        const { href } = document.location
        const copyright = ` ${window.copied_text} ${href}`
        const text = selection + copyright
        const divElement = document.createElement('div')

        divElement.style.position = 'absolute'
        divElement.style.left = '-99999px'

        const textNode = document.createTextNode(text) // создал текстовый узел
        divElement.appendChild(textNode) // и добавил его
        bodyElement.appendChild(divElement)
        selection.selectAllChildren(divElement)
        setTimeout(function () {
          bodyElement.removeChild(divElement)
          selection.removeAllRanges()
          selection.addRange(range)
        }, 0)
      }
    }
  }
}

export const TABOOLA_ID = 1348520
function initTaboola() {
  window._tfa = window._tfa || []
  window._tfa.push({ notify: 'event', name: 'page_view', id: TABOOLA_ID })
  !(function (t, f, a, x) {
    if (!document.getElementById(x)) {
      t.async = 1
      t.src = a
      t.id = x
      f.parentNode.insertBefore(t, f)
    }
  })(
    document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1348520/tfa.js',
    'tb_tfa_script'
  )
}
