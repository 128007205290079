import * as C from '../consts'

export function updateCard(payment_method) {
  return {
    types: C.UPDATE_CARD,
    method: payment_method ? 'POST' : 'DELETE',
    url: `/api/current_user/payments/payment_methods`,
    body: { payment_method },
  }
}
