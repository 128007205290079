import { Settings } from 'luxon'
import Cookies from 'js-cookie'
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'
import * as C from '../consts'
// import * as M from '../models';

/* PROFILE */

export const currentUser = api(
  C.GET_CURRENT_USER,
  Object,
  null,
  action => action.data.current_user
)

const defaultTimezone = Cookies.get('timezone') || Settings.defaultZone

export const timezone = boundReducer(defaultTimezone, {
  [C.SET_TIMEZONE]: (_state, action) => action.value,
})

export const country = boundReducer(
  {},
  {
    [C.SET_USER_COUNTRY]: (_state, action) => action.country,
  }
)

export const companyCountry = boundReducer(
  {},
  {
    [C.SET_COMPANY_COUNTRY]: (_state, action) => action.country,
  }
)

export const transactions = api(C.GET_TRANSACTIONS, Array)

/* / PROFILE */

export const newslettersPopupDates = api(C.NEWSLETTERS_POPUP_DATES, Object)
export const sendToPardot = api(C.SEND_TO_PARDOT, Object)
