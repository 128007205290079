import Component from 'components/component'
import { Link } from 'components/link'
import { Modal } from 'components/modal'
import ViewCollapse from 'components/view-collapse'
import { cookiePolicy } from 'config/routes'
import getAcceptedCookies from 'helpers/cookies'
import init from 'init'
import Cookies from 'js-cookie'
import { DI } from 'modules'
import React, { useCallback, useEffect, useState } from 'react'

function HeaderCookieToggle({
  disabled = false,
  status = true,
  callbackHandle = status => {},
  closeModalHandle = () => {},
  strings,
}) {
  function enabledHandle(event) {
    event.preventDefault()
    callbackHandle(true)
  }

  function disabledHandle(event) {
    event.preventDefault()
    callbackHandle(false)
  }

  return (
    <>
      <div className="ae-filter-relevant__desktop ae-filter-relevant__desktop--cookies">
        <div className="ae-sort">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={Component.classList(
              'ae-sort__item',
              status && '_active'
            )}
            href="#"
            onClick={enabledHandle}
          >
            {strings['header.cookies.toggle.enabled']}
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={Component.classList(
              'ae-sort__item',
              !status && !disabled && '_active',
              disabled && '_disabled'
            )}
            href="#"
            onClick={disabledHandle}
          >
            {strings['header.cookies.toggle.disabled']}
          </a>
        </div>
      </div>
      <div className="stream__help">
        {strings['header.cookies.help.piece_1']}{' '}
        <Link onClick={closeModalHandle} to={cookiePolicy.getPath()}>
          Cookie Policy
        </Link>{' '}
        {strings['header.cookies.help.piece_2']}
      </div>
    </>
  )
}

function HeaderCookie(props) {
  const [show, setShow] = useState(false)
  const { strings } = props.common
  const [acceptConfig, updateAcceptConfig] = useState({
    strictlyNecessary: true,
    performance: true,
    functional: true,
    marketing: true,
  })

  const [isOpenSettings, setOpenSettings] = useState(false)

  useEffect(() => {
    const cookiesList = getAcceptedCookies()
    setShow(!cookiesList)
    if (cookiesList) props.actions.common.setCookiesAccepted(cookiesList)
  }, [props.actions.common])

  async function acceptHandle() {
    Cookies.set('cookiesAccepted', acceptConfig, { expires: 365 })
    init(acceptConfig)
    props.actions.common.setCookiesAccepted(acceptConfig)
    setOpenSettings(false)
    setShow(false)
  }

  const togglePerformanceHandle = useCallback(status => {
    updateAcceptConfig(prev => ({
      ...prev,
      performance: status,
    }))
  }, [])

  const toggleStrictlyNecessaryHandle = useCallback(status => {
    updateAcceptConfig(prev => ({
      ...prev,
      strictlyNecessary: status,
    }))
  }, [])

  const toggleFunctionalHandle = useCallback(status => {
    updateAcceptConfig(prev => ({
      ...prev,
      functional: status,
    }))
  }, [])

  const toggleMarketingHandle = useCallback(status => {
    updateAcceptConfig(prev => ({
      ...prev,
      marketing: status,
    }))
  }, [])

  const closeSettingsHandle = useCallback(() => {
    setOpenSettings(false)
  }, [])

  return (
    <>
      <div className="header-cookies-float">
        <div className="header-cookies" style={{ height: show ? 'auto' : 0 }}>
          <div className="iwrap header-cookies-inner">
            <span className="header-cookies__text">
              {strings['header.cookies.message']}
            </span>
            <div className="header-cookies__buttons">
              <button
                className="btn-bord button header-cookies__button"
                onClick={() => setOpenSettings(true)}
                type="button"
              >
                {strings['header.cookies.settings']}
              </button>
              <button
                className="button button--small-grey header-cookies__button"
                type="button"
                onClick={acceptHandle}
              >
                {strings['header.cookies.accept']}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpenSettings && (
        <Modal onClose={closeSettingsHandle}>
          <div className="popup-stream">
            <span className="popup__title">
              {strings['header.cookies.modal.title']}
            </span>
            <div className="stream">
              <div className="stream-form stream-form--cookies">
                <div className="stream__help">
                  {strings['header.cookies.modal.description.piece_1']}
                  <br />
                  {strings['header.cookies.modal.description.piece_2']}
                  <br />
                  {strings['header.cookies.modal.description.piece_3']}
                </div>
              </div>
            </div>

            <ViewCollapse
              basedClass="stream-manual"
              title={strings['header.cookies.strictlyNecessary.caption']}
            >
              <div className="stream__help stream-manual-list">
                {
                  strings[
                    'header.cookies.strictlyNecessary.description.piece_1'
                  ]
                }
                <br />
                {
                  strings[
                    'header.cookies.strictlyNecessary.description.piece_2'
                  ]
                }
              </div>
              <HeaderCookieToggle
                callbackHandle={toggleStrictlyNecessaryHandle}
                closeModalHandle={closeSettingsHandle}
                disabled
                status={acceptConfig.strictlyNecessary}
                strings={strings}
              />
            </ViewCollapse>
            <ViewCollapse
              basedClass="stream-manual"
              title={strings['header.cookies.performance.caption']}
            >
              <div className="stream__help stream-manual-list">
                {strings['header.cookies.performance.description']}
              </div>
              <HeaderCookieToggle
                callbackHandle={togglePerformanceHandle}
                closeModalHandle={closeSettingsHandle}
                status={acceptConfig.performance}
                strings={strings}
              />
            </ViewCollapse>
            <ViewCollapse
              basedClass="stream-manual"
              title={strings['header.cookies.functional.caption']}
            >
              <div className="stream__help stream-manual-list">
                {strings['header.cookies.functional.description']}
              </div>
              <HeaderCookieToggle
                callbackHandle={toggleFunctionalHandle}
                closeModalHandle={closeSettingsHandle}
                status={acceptConfig.functional}
                strings={strings}
              />
            </ViewCollapse>
            <ViewCollapse
              basedClass="stream-manual"
              title={strings['header.cookies.marketing.caption']}
            >
              <div className="stream__help stream-manual-list">
                {strings['header.cookies.marketing.description.piece_1']} <br />
                {strings['header.cookies.marketing.description.piece_2']}
              </div>
              <HeaderCookieToggle
                callbackHandle={toggleMarketingHandle}
                closeModalHandle={closeSettingsHandle}
                status={acceptConfig.marketing}
                strings={strings}
              />
            </ViewCollapse>

            <div className="popup-buttons popup-buttons--border _fixed">
              <div className="popup-button">
                <button
                  className="text-button"
                  onClick={closeSettingsHandle}
                  type="button"
                >
                  {strings['header.cookies.modal.cancel']}
                </button>
              </div>
              <div className="popup-button">
                <button className="button" onClick={acceptHandle} type="button">
                  {strings['header.cookies.modal.submit']}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default DI()(HeaderCookie)
