import * as C from './consts'

export function getDashboard(search = {}) {
  return {
    types: C.GET_DASHBOARD,
    url: `/api/content/video_projects/dashboard`,
    search,
  }
}

export const wsStatusChangeEventDashboard = data => (dispatch, getState) => {
  let lives = [...getState().dashboard.dashboard.payload.live_events]
  const currentItemIndex = lives.findIndex(item => item.id === data.id)

  if (currentItemIndex > -1) {
    const isUpdate = [
      'confirmed',
      'delayed',
      'ahead',
      'live',
      'next',
      'finished',
    ].includes(data.status)

    const isNeedRemove = !isUpdate || data.front_page_ban || !data.visible

    if (isNeedRemove) {
      lives.splice(currentItemIndex, 1)
    } else if (isUpdate) {
      lives[currentItemIndex] = data
    }
  } else {
    const isNeedAdded =
      ['confirmed', 'delayed', 'ahead', 'live', 'next'].includes(data.status) &&
      !data.front_page_ban &&
      data.visible

    if (isNeedAdded) {
      lives = [data, ...lives]
    }
  }

  lives = lives.sort((a, b) => new Date(a.start_at) - new Date(b.start_at))

  dispatch({
    type: C.GET_DASHBOARD.SUCCESS,
    data: {
      ...getState().dashboard.dashboard.payload,
      live_events: lives,
    },
  })
}
