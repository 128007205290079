import { push } from 'connected-react-router'
import Cookies from 'js-cookie'
import { Settings } from 'luxon'
import { euCountries } from 'config/constants'
import * as routes from 'config/routes'
import { hidden, visibilityChange } from 'helpers/document'
import * as myContentConfig from 'pages/business/user/my-content/config'
import getAcceptedCookies from '../../helpers/cookies'
import { GET_COUNTRIES } from '../geodata/consts'
import * as user from './actions'
import * as C from './consts'

export default ({ getState, dispatch }) => {
  document.addEventListener(
    visibilityChange,
    () => {
      if (!document[hidden]) {
        dispatch(user.getCurrentUser()).catch(() => {})
      }
    },
    false
  )

  return next => action => {
    const cookiesList = getAcceptedCookies()
    function dispatchUserCountries(user, countries) {
      let userCountry = {}
      let companyCountry = {}

      if (user.id) {
        userCountry =
          countries.find(country => country.id === user.country_id) || {}
        companyCountry =
          countries.find(country => country.id === user.company.country_id) ||
          {}
      }

      userCountry.isEU = euCountries.includes(userCountry.code)
      companyCountry.isEU = euCountries.includes(companyCountry.code)

      dispatch({
        type: C.SET_USER_COUNTRY,
        country: userCountry,
      })

      dispatch({
        type: C.SET_COMPANY_COUNTRY,
        country: companyCountry,
      })
    }

    if ([C.SIGN_IN.FAILURE].includes(action.type)) {
      const { body } = action.error.response
      if (body && body.errors) {
        const locked =
          body.errors.findIndex(el => el.code === 'user_locked') !== -1
        const { locale } = getState().common
        if (locked) {
          dispatch(push(routes.signinDeactivated.getPath(locale)))
        }
      }
      throw action.error
    }

    if (
      [
        C.SIGN_IN.SUCCESS,
        C.UPDATE_CURRENT_USER.SUCCESS,
        C.UPDATE_BILLING_INFO.SUCCESS,
        C.UPDATE_CARD.SUCCESS,
        C.UPDATE_CARD.SUCCESS,
        C.PAY.SUCCESS,
      ].includes(action.type)
    ) {
      dispatch(user.getCurrentUser()).catch(() => {})
    }

    if ([C.SIGN_OUT.SUCCESS].includes(action.type)) {
      dispatch({
        type: C.GET_CURRENT_USER.SUCCESS,
        data: {
          current_user: {},
        },
      })

      dispatch({
        type: C.GET_AGREEMENTS.RESET,
      })

      dispatch({
        type: C.GET_ACTIVE_AGREEMENTS.RESET,
      })
      dispatch(user.getCurrentUser()).catch(() => {})
    }

    if ([C.GET_CURRENT_USER.SUCCESS].includes(action.type)) {
      const { time_zone } = action.data.current_user

      ;(function () {
        this.agreement_types = this.agreement_types || []

        this.have_one_off = this.agreement_types.includes('one_off')
        this.have_pass = this.agreement_types.includes('pass')

        this.have_enterpise =
          this.have_one_off && this.have_pass && this.agreement_types.length > 2
        this.only_one_off =
          this.have_one_off && this.agreement_types.length === 1
      }).call(action.data.current_user)

      if (time_zone) {
        dispatch(user.setTimezone(time_zone))
      }

      dispatch(user.getAgreements())

      const countries = getState().geodata.countries.payload
      dispatchUserCountries(action.data.current_user, countries)
      // dispatch(user.getLists());
    }

    if ([C.SET_TIMEZONE].includes(action.type)) {
      if (cookiesList && cookiesList.functional) {
        Cookies.set('timezone', action.value)
      }
      Settings.defaultZone = action.value
    }

    if ([C.SELECT_TIMEZONE].includes(action.type)) {
      const { isSignedIn } = getState().user
      const time_zone = action.value

      dispatch(user.setTimezone(time_zone))

      if (isSignedIn) {
        dispatch(user.updateCurrentUser({ time_zone }))
      }
    }

    if (action.type === C.GET_ACTIVE_AGREEMENTS.SUCCESS) {
      return next({
        ...action,
        data: action.data || [],
      })
    }

    if (action.type === C.GET_AGREEMENTS.SUCCESS) {
      const isMaster =
        getState().user.currentUser.payload.user_group === 'customer'

      let data = action.data || []

      if (!isMaster) {
        data = data.filter(item => {
          if (item.status === 'user_accepting') {
            action.haveUnacceptedAgreements = true
          }
          return item.status === 'active'
        })
      }

      return next({
        ...action,
        data,
      })
    }

    if ([C.SET_TIMEZONE].includes(action.type)) {
      if (cookiesList && cookiesList.functional) {
        Cookies.set('timezone', action.value)
      }
      // moment.tz.setDefault(action.value);
      Settings.defaultZone = action.value
    }

    if ([GET_COUNTRIES.SUCCESS].includes(action.type)) {
      const { currentUser } = getState().user
      dispatchUserCountries(currentUser.payload, action.data)
    }

    if (
      [C.INVITE_MEMBER.SUCCESS, C.DELETE_MEMBER.SUCCESS].includes(action.type)
    ) {
      dispatch(user.getCompany())
    }

    if (action.type === C.ACCEPT_AGREEMENT.SUCCESS) {
      dispatch(user.getAgreements())
      dispatch(user.getCurrentUser()).catch(() => {})
    }

    if (
      [
        C.INVITE_MEMBER.SUCCESS,
        C.DELETE_MEMBER.SUCCESS,
        C.UPDATE_MEMBER.SUCCESS,
      ].includes(action.type)
    )
      dispatch(user.getCompany())

    if ([C.PP_SUBSCRIPTIONS_PROCEED.SUCCESS].includes(action.type)) {
      document.location.href = action.data.redirect_url
    }

    if (
      [
        C.PP_SUBSCRIPTIONS_PAY.SUCCESS,
        C.PP_SUBSCRIPTIONS_PAY.FAILURE,
        C.SET_AUTO_RENEWAL.SUCCESS,
        C.CREATE_USER_LIMIT.SUCCESS,
        C.UPDATE_USER_LIMIT.SUCCESS,
        C.DELETE_USER_LIMIT.SUCCESS,
      ].includes(action.type)
    ) {
      dispatch(user.getAgreements())
    }

    if (action.type === C.CANCEL_VIDEO_REQUEST.SUCCESS) {
      dispatch(user.getRequests())
    }

    if (action.type === C.DOWNLOAD_REQUESTED_VIDEO.SUCCESS) {
      const { searchParams, pagination } = getState().common

      dispatch(user.getRequests())
      pagination.pages.map(page =>
        dispatch(
          user.getEntities({
            ...searchParams,
            per_page: myContentConfig.PER_PAGE,
            page,
          })
        )
      )

      if (action.data.exist) {
        const iframe = document.createElement('iframe')
        iframe.src = action.data.url

        document.body.appendChild(iframe)
      }
    }

    if ([C.UPDATE_LIST.SUCCESS, C.DELETE_LIST.SUCCESS].includes(action.type)) {
      dispatch(user.getLists())
    }

    return next(action)
  }
}
