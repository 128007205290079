import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from './actions'

/**
 * Форма
 *
 * @constructor
 * @this  {NewForm}
 * @param {Object} props
 * @param {Function} [props.onChange=(ev, form, json)] - Коллбэк, срабатывающий на событие change
 * @param {Function} [props.onSubmit=(ev, form, json)] - Коллбэк, срабатывающтй на событие submit
 * @param {String} [props.serialize='json'] - Формат сериализации формы (json, array, url, formData)

 *
 *
 * @example
 * import Form from 'common/components/form/new-form';
 *
 * const onChange = (ev, form, json) => this.setState({form})
 * <Form onChange={onChange}>
 *
 */
class NewForm extends React.Component {
  static defaultProps = {
    onChange() {},
    onSubmit() {},
    onReset() {},
    isValid() {
      return true
    },
    serialize: 'json',
    type: 'CHANGE_FORM',
    defaultState: {},
    autoComplete: false,
    onlyWithValue: false,
    style: {},
  }

  submit = () => {
    this.dispatch('submit')
  }

  dispatch = type => {
    const event = new Event(type, {
      bubbles: true,
    })
    this.form.dispatchEvent(event)
  }

  onChange = ev => {
    if (
      ['textarea', 'text', 'number'].includes(ev.target.type) &&
      ev.type === 'change'
    ) {
      return
    }
    if (['checkbox', 'radio'].includes(ev.target.type) && ev.type === 'input') {
      return
    }
    const form = ev.currentTarget

    const serialized = this.serializeTo(this.props.serialize, form)
    const state = this.serializeJSON(form)

    this.props.onChange(ev, serialized, state)

    this.props.actions.setStateValue(this.props.type, state)
  }

  onSubmit = ev => {
    ev.preventDefault()
    ev.stopPropagation()

    const form = ev.currentTarget

    const serialized = this.serializeTo(this.props.serialize, form)
    const state = this.serializeJSON(form)

    if (!this.props.isValid(form, state)) {
      return false
    }

    this.props.onSubmit(ev, serialized, state)
  }

  render() {
    return (
      <form
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onReset={this.props.onReset}
        onKeyUp={this.props.onKeyUp}
        onInput={this.onChange}
        autoComplete={this.props.autoComplete ? 'on' : 'off'}
        ref={el => (this.form = el)}
        className={this.props.formClassName}
        style={this.props.style}
      >
        {!this.props.autoComplete && (
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{ display: 'none' }}
          />
        )}
        {this.props.children}
      </form>
    )
  }

  serializeTo(format, form) {
    switch (format) {
      case 'array':
        return this.serializeArray(form)
      case 'json':
        return this.serializeJSON(form)
      case 'url':
        return this.serializeURL(form)
      default:
        return new FormData(form)
    }
  }

  serializeArray(form) {
    return [...form.elements]
      .filter(element => {
        return (
          (!element.hasOwnProperty('checked') || element.checked) &&
          element.name &&
          (this.props.onlyWithValue ? element.value : true)
        )
      })
      .map(element => {
        const { name, value } = element
        return { name, value }
      })
  }

  serializeJSON(form) {
    return this.nestedJSON(this.serializeArray(form))
  }

  nestedJSON(arr) {
    const SEPARATOR = '['
    const BEGIN = '$$BEGIN'
    const END = '$$END'

    function makePath(name) {
      name = name[0] === SEPARATOR ? BEGIN + name : BEGIN + SEPARATOR + name

      return name
        .split(SEPARATOR)
        .concat(END)
        .map(function (item) {
          return item.replace(/\]$/, '')
        })
    }

    let json = {}

    arr.forEach(function (item) {
      if (!item.name || item.name === 'hidden') {
        return
      }
      let path = makePath(item.name)
      path.reduce(function (acc, curr, index, array) {
        if (curr === END) return void 0
        let next = array[index + 1]
        let value

        if (isNaN(+next)) {
          value = acc[curr] || {}
        } else {
          value = acc[curr] || []
        }

        if (next === END) {
          value = item.value
        }

        if (typeof value === 'string') {
          let _value = value.trim()
          if (_value.match(/(^(-)?[0-9]+)((e(-)?)?[0-9]+)$/)) {
            value = +_value
          } else if (_value.match(/^(true|false)$/)) {
            value = JSON.parse(_value)
          }
        }

        if (acc instanceof Array && curr === '') {
          const last = acc[acc.length - 1]

          if (
            !last ||
            (last.hasOwnProperty(next) && typeof last[next] !== 'object') ||
            typeof last !== 'object'
          ) {
            acc.push(value)
            return acc[acc.length - 1]
          } else {
            return last
          }
        } else {
          acc[curr] = value
          return acc[curr]
        }
      }, json)
    })

    return {
      ...this.props.defaultState,
      ...json[BEGIN],
    }
  }

  serializeURL(form) {
    const array = this.serializeArray(form)

    return array
      .filter(item => Boolean(item.value))
      .map(item => `${item.name}=${item.value}`)
      .join('&')
  }
}

export default connect(
  null,
  dispatch => ({
    actions: bindActionCreators(actions, dispatch),
  }),
  null,
  { forwardRef: true }
)(NewForm)
