import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'
import HeaderLangItem from './header-lang-item'
import { ruLocaleOnly } from 'config'
import { IS_LANDING } from '../../config'

export class HeaderLang extends Component {
  render() {
    const exlcudeLocales = IS_LANDING ? ['ar', 'es'] : ruLocaleOnly ? ['en', 'ar', 'es'] : ['ru', 'es']

    const locales =
      Array.isArray(this.props.locales.list.payload) &&
      this.props.locales.list.payload.filter(
        locale => !exlcudeLocales.includes(locale.code)
      )

    return (
      <div className="header-lang">
        <div className="iwrap">
          <ul className="header-lang-list">
            {locales.map(locale => {
              return <HeaderLangItem key={locale.code} locale={locale} />
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default DI(['locales', 'common'])(HeaderLang)
