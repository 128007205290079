import * as C from './consts'
import * as M from './models'
import noimg from 'images/noimg.jpg'
import large_noimg from 'images/large_noimg.png'

import { boundApiReducer as api } from 'redux-upgrader'

const noImage = noimg
const noImageLarge = large_noimg

const noimgs = {
  thumbnail: { url: noImage },
  url: noImageLarge,
  v273x154: { url: noImage },
  v575x323: { url: noImageLarge },
  v960x540: { url: noImageLarge },
  large_brand: { url: noImageLarge },
}

export const list = api(C.GET_LIST, Array, null, (action, state) => {
  const { page = 1 } = action.search || {}
  action.data = action.data.map(item => {
    if (item.image_type === 'default') {
      return {
        ...item,
        image: noimgs,
      }
    }
    return item
  })

  if (page === 1) {
    return action.data
  } else {
    return state.payload.concat(action.data)
  }
})

export const listRecordings = api(
  C.GET_LIST_RECORDINGS,
  Array,
  null,
  (action, state) => {
    const { page = 1 } = action.search || {}
    action.data = action.data.map(item => {
      if (item.image_type === 'default') {
        return {
          ...item,
          image: noimgs,
        }
      }
      return item
    })

    if (page === 1) {
      return action.data
    } else {
      return state.payload.concat(action.data)
    }
  }
)

export const listMyEvents = api(C.GET_LIST_MY_EVENTS, M.MyEvents)

export const listArchivedEvents = api(
  C.GET_LIST_ARCHIVED_EVENTS,
  Array,
  null,
  (action, state) => {
    const { page = 1 } = action.search || {}
    action.data = action.data.map(item => {
      if (item.image_type === 'default') {
        return {
          ...item,
          image: noimgs,
        }
      }
      return item
    })

    if (page === 1) {
      return action.data
    } else {
      return state.payload.concat(action.data)
    }
  }
)

export const byID = api(
  C.GET_LIVE,
  M.LiveEvent,
  action => action.id,
  action => {
    const checkEnabled = value => Boolean(Number(value))
    const { stream_to } = action.data

    return {
      ...action.data,
      image: action.data.image_type === 'default' ? noimgs : action.data.image,
      streamTo: {
        enabledFacebook:
          checkEnabled(stream_to && stream_to.facebook.base) ||
          checkEnabled(stream_to && stream_to.facebook.api),
        enabledYouTube: checkEnabled(stream_to && stream_to.youtube),
        enabledPeriscope: checkEnabled(stream_to && stream_to.periscope),
        enabledVkontakte: checkEnabled(stream_to && stream_to.vkontakte),
        enabledOdnoklassniki: checkEnabled(
          stream_to && stream_to.odnoklassniki
        ),
        enabledRTMP720: checkEnabled(stream_to && stream_to.custom_rtmp.hd),
        enabledRTMP1080: checkEnabled(
          stream_to && stream_to.custom_rtmp.full_hd
        ),
        enabledSRT: checkEnabled(stream_to && stream_to.srt),
        enabledHLSFullHd: checkEnabled(stream_to && stream_to.hls_full_hd),
        enabledTVU: checkEnabled(stream_to && stream_to.tvu),
      },
    }
  }
)
