import React from 'react'
import { DI } from 'modules'

export function NotificationsItem(props) {
  const closeNotifiction = () => {
    props.actions.notifications.deleteNotification(props.id)
  }

  return (
    <div
      className={`notifications-block__content notifications-block__content--${props.notificationType}`}
    >
      <div className="notifications-block__inner">
        <span className="notifications-block__text">{props.children}</span>
        {props.buttons.length ? (
          <div className="notifications-block__button-container">
            {props.buttons.map((button, index) => (
              <button
                key={index}
                className="button button--notify notifications-block__button"
                {...button}
              />
            ))}
          </div>
        ) : null}
        <button
          className="notifications-block__close"
          onClick={closeNotifiction}
        />
      </div>
    </div>
  )
}

export default DI(['notifications'])(NotificationsItem)
