import * as C from './consts'

export function validateToken(token) {
  return {
    types: C.VALIDATE_TOKEN,
    method: 'POST',
    url: '/api/recaptcha_verifications',
    body: {
      recaptcha_response: token,
    },
  }
}
