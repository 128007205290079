import React from 'react'
import { DI } from 'modules'
import { Link } from 'components/link'
import * as routes from 'config/routes'
import { IS_LANDING } from 'config'
import { withRouter } from 'react-router'
import { MAIN_HOST } from 'config'

function FooterMenuItem(props) {
  return (
    <div className="footer-menu-item">
      <Link to={props.link} className="footer-menu__link">
        {props.text}
      </Link>
    </div>
  )
}

export function FooterBlockMenu(props) {
  const {
    common: { strings },
    user,
    location,
  } = props
  const [_, currentLocale = 'en'] = location.pathname.match(/^\/(\w{2})/) || []

  return !(IS_LANDING && currentLocale === 'en') ? (
    <div className="footer-block-menu">
      <div className="footer-block footer-block--menu">
        <FooterMenuItem
          link={MAIN_HOST + routes.livesList.getPath()}
          text={strings['header.menu.live']}
        />
        <FooterMenuItem
          link={MAIN_HOST + routes.videosList.getPath()}
          text={strings['header.menu.vod']}
        />
        {/* RPTL-94
                <FooterMenuItem
                    link={routes.broadcast.getPath()}
                    text={strings['header.menu.broadcast']}
                /> */}
        <FooterMenuItem
          link={MAIN_HOST + routes.choosePlan.getPath()}
          text={strings['header.menu.pricing']}
        />
      </div>
      <div className="footer-block footer-block--menu">
        {!IS_LANDING && (
          <FooterMenuItem
            link={routes.tos.getPath()}
            text={strings['footer.link.tos']}
          />
        )}
        <FooterMenuItem
          link={routes.privacyPolicy.getPath()}
          text={strings['footer.link.privacy']}
        />
        <FooterMenuItem
          link={routes.cookiePolicy.getPath()}
          text={strings['footer.link.cookie']}
        />
        <FooterMenuItem
          link={MAIN_HOST + routes.impressum.getPath()}
          text={strings['footer.link.impressum']}
        />
      </div>
      <div className="footer-block footer-block--menu">
        <FooterMenuItem
          link={MAIN_HOST + routes.about.getPath()}
          text={strings['footer.link.about']}
        />
        <FooterMenuItem
          link={routes.careersList.getPath()}
          text={strings['header.menu.careers']}
        />
      </div>
    </div>
  ) : (
    <div className="footer-block-menu">
      {!IS_LANDING && (
        <div className="footer-block footer-block--menu">
          <FooterMenuItem
            link={routes.tos.getPath()}
            text={strings['footer.link.tos']}
          />
        </div>
      )}
      <div className="footer-block footer-block--menu">
        <FooterMenuItem
          link={routes.privacyPolicy.getPath()}
          text={strings['footer.link.privacy']}
        />
      </div>
      <div className="footer-block footer-block--menu">
        <FooterMenuItem
          link={routes.cookiePolicy.getPath()}
          text={strings['footer.link.cookie']}
        />
      </div>
    </div>
  )
}

export default DI(['user', 'location'])(withRouter(FooterBlockMenu))
