import PageLoading from 'components/page-loading'
import React from 'react'
// import loadable from 'react-loadable'
import loadable from "@loadable/component";

import { createRoute } from 'utils/routing'

const loading = () => <PageLoading />

/* Business */

export const checkEmail = createRoute({
  path: '/:locale/check-email',
  component: loadable(() =>
    import(
        /* webpackChunkName: "check-email" */ 'pages/business/check-email'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const notFound = createRoute({
  component: loadable(() =>
    import(/* webpackChunkName: "not-found" */ 'pages/content/not-found'),
    {
      fallback: loading
    }
  ),
})

export const requestPlan = createRoute({
  path: '/:locale/plans/request-:name',
  component: loadable(() =>
    import(
        /* webpackChunkName: "request-plan" */ 'pages/business/request-plan'
    ),
    {
      fallback: loading
    }
  ),
})

export const user = createRoute({
  path: '/:locale/user',
  component: loadable(() => import(/* webpackChunkName: "user" */ 'pages/business/user'),
    {
      fallback: loading
    }
  ),
})

export const myContent = createRoute({
  path: '/:locale/user',
  exact: true,
  component: loadable(() =>
    import(
        /* webpackChunkName: "profile" */ 'pages/business/user/my-content'
    ),
    {
      fallback: loading
    }
  ),
})

export const profile = createRoute({
  path: '/:locale/user/profile',
  component: loadable(() =>
    import(/* webpackChunkName: "profile" */ 'pages/business/user/profile'),
    {
      fallback: loading
    }
  ),
})

export const planDetails = createRoute({
  path: '/:locale/user/plan-details',
  component: loadable(() =>
    import(
        /* webpackChunkName: "profile" */ 'pages/business/user/plan-details'
    ),
    {
      fallback: loading
    }
  ),
})

export const billingSettings = createRoute({
  path: '/:locale/user/billing-settings',
  component: loadable(() =>
    import(
        /* webpackChunkName: "billing-settings" */ 'pages/business/user/billing-settings'
    ),
    {
      fallback: loading
    }
  ),
})

export const purchaseHistory = createRoute({
  path: '/:locale/user/purchase-history',
  component: loadable(() =>
    import(
        /* webpackChunkName: "profile" */ 'pages/business/user/purchase-history'
    ),
    {
      fallback: loading
    }
  ),
})

export const myList = createRoute({
  path: '/:locale/user/my-lists',
  component: loadable(() =>
    import(/* webpackChunkName:"my-lists" */ 'pages/business/user/my-lists'),
    {
      fallback: loading
    }
  ),
})

export const myLists = createRoute({
  path: '/:locale/user/my-lists',
  component: loadable(() =>
    import(
        /* webpackChunkName: "my-lists-list" */ 'pages/business/user/my-lists/list'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const myListsView = createRoute({
  path: '/:locale/user/my-lists/:id',
  component: loadable(() =>
    import(
        /* webpackChunkName: "my-lists-view" */ 'pages/business/user/my-lists/view'
    ),
    {
      fallback: loading
    }
  ),
})

export const members = createRoute({
  path: '/:locale/user/members',
  component: loadable(() =>
    import(/* webpackChunkName: "members" */ 'pages/business/user/members'),
    {
      fallback: loading
    }
  ),
})

export const userLimits = createRoute({
  path: '/:locale/user/user-limits',
  component: loadable(() =>
    import(
        /* webpackChunkName: "user-limits" */ 'pages/business/user/user-limits'
    ),
    {
      fallback: loading
    }
  ),
})

export const planning = createRoute({
  path: '/:locale/user/planning',
  component: loadable(() =>
    import(
        /* webpackChunkName: "planning-section" */ 'pages/business/user/planning'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const joinTeam = createRoute({
  path: '/:locale/join-team',
  component: loadable(() =>
    import(
        /* webpackChunkName: "confirmation" */ 'pages/business/confirmation'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const finalStep = createRoute({
  path: '/:locale/final-step',
  component: loadable(() =>
    import(
        /* webpackChunkName: "confirmation" */ 'pages/business/confirmation'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const alreadyConfirmed = createRoute({
  path: '/:locale/already-confirmed',
  component: loadable(() =>
    import(
        /* webpackChunkName: "alredy-confirmed" */ 'pages/business/confirmation/alredy-confirmed'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const signin = createRoute({
  path: '/:locale/signin',
  component: loadable(() =>
    import(/* webpackChunkName: "sign-in" */ 'pages/business/sign-in'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const signinDeactivated = createRoute({
  path: '/:locale/signin-deactivated',
  component: loadable(() =>
    import(
        /* webpackChunkName: "sign-in_deactivated" */ 'pages/business/sign-in/deactivated'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const signup = createRoute({
  path: '/:locale/signup',
  component: loadable(() =>
    import(/* webpackChunkName: "sign-up" */ 'pages/business/sign-up'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const restorePassword = createRoute({
  path: '/:locale/restore-password',
  component: loadable(() =>
    import(
        /* webpackChunkName: "members-view" */ 'pages/business/restore-password'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const restorePasswordCheck = createRoute({
  path: '/:locale/restore-password/check-email',
  component: loadable(() =>
    import(
        /* webpackChunkName: "members-view" */ 'pages/business/restore-password/check-email'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const newPassword = createRoute({
  path: '/:locale/new-password',
  component: loadable(() =>
    import(
        /* webpackChunkName: "members-view" */ 'pages/business/new-password'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const newPasswordChanged = createRoute({
  path: '/:locale/new-password/changed',
  component: loadable(() =>
    import(
        /* webpackChunkName: "members-view" */ 'pages/business/new-password/changed'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const membersList = createRoute({
  path: '/:locale/user/members',
  component: loadable(() =>
    import(
        /* webpackChunkName: "members-list" */ 'pages/business/user/members/list'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const membersView = createRoute({
  path: '/:locale/user/members/:id',
  component: loadable(() =>
    import(
        /* webpackChunkName: "members-view" */ 'pages/business/user/members/view'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const payment = createRoute({
  path: '/:locale/payment/:type',
  component: loadable(() =>
    import(/* webpackChunkName: "payment" */ 'pages/business/payment-new'),
    {
      fallback: loading
    }
  ),
  exact: true,
})

export const paymentProcessing = createRoute({
  path: '/:locale/payment-processing',
  component: loadable(() =>
    import(
        /* webpackChunkName: "payment-processing" */ 'pages/business/payment-processing'
    ),
    {
      fallback: loading
    }
  ),
  exact: true,
})