import Component from 'components/component'
import * as routes from 'config/routes'
import { DI } from 'modules'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export class PrivateRoute extends Component {
  render() {
    const { isSignedIn } = this.props.user
    const { component, ignorePrivate, ...route } = this.props

    if (isSignedIn || ignorePrivate) {
      const plan = this.props.common.searchParams.plan || ''

      if (plan === 'pass-monthly-eur') {
        return (
          <Redirect
            to={{
              pathname: route.location.pathname,
              search: '?plan=pass-annually-eur',
            }}
          />
        )
      }

      return <Route {...route} component={component} />
    } else {
      if (
        this.props.user.currentUser.pending ||
        !this.props.user.currentUser.isInited
      ) {
        return (
          <Route
            {...route}
            render={() => {
              ;<section className="content">
                <div className="wrap wrap--high wrap--loading"></div>
              </section>
            }}
          />
        )
      } else {
        return <Route {...route} component={routes.signin.component} />
      }
    }
  }
}

export default DI(['user', 'common'])(PrivateRoute)
