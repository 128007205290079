import { ApiAction } from 'redux-upgrader'

/* CONTENT */

export const GET_VIDEO = new ApiAction('GET_VIDEO')
export const GET_DURATION = new ApiAction('GET_DURATION')
export const GET_LIST = new ApiAction('GET_LIST_VIDEOS')
export const CLEAR_LIST_VIDEOS = new ApiAction('CLEAR_LIST_VIDEOS')
export const GET_RELATED_LIST = new ApiAction('GET_RELATED_LIST')
export const GET_SUGGESTS = new ApiAction('GET_SUGGESTS')

/* BUSINESS */

export const GET_AVAILABLE_FORMATS = new ApiAction('GET_AVAILABLE_FORMATS')
export const DOWNLOAD_VIDEO = new ApiAction('DOWNLOAD_VIDEO')
export const PREVIEW_DOWNLOAD_VIDEO = new ApiAction('PREVIEW_DOWNLOAD_VIDEO')
export const REQUEST_WORLD_EXCLUSIVE = new ApiAction('REQUEST_WORLD_EXCLUSIVE')
export const SEND_STATS_HIT = new ApiAction('SEND_STATS_HIT')
export const SET_PAYMENT_ALERT = 'SET_PAYMENT_ALERT'
