import { ApiAction } from 'redux-upgrader'

/* session */
export const SIGN_IN = new ApiAction('SIGN_IN')
export const SIGN_OUT = new ApiAction('SIGN_OUT')
export const SEND_RESET_PASSWORD_INSTRUCTIONS = new ApiAction(
  'SEND_RESET_PASSWORD_INSTRUCTIONS'
)
export const UPDATE_PASSWORD = new ApiAction('UPDATE_PASSWORD')

export const ADMIN_VERIFY = new ApiAction('ADMIN_VERIFY')
export const VERIFY_OTP = new ApiAction('VERIFY_OTP')
export const REGISTRATION_BY_OTP = new ApiAction('REGISTRATION_BY_OTP')

/* profile */
export const SIGN_UP = new ApiAction('SIGN_UP')
export const GET_CURRENT_USER = new ApiAction('GET_CURRENT_USER')
export const UPDATE_CURRENT_USER = new ApiAction('UPDATE_CURRENT_USER')
export const UPDATE_BILLING_INFO = new ApiAction('UPDATE_BILLING_INFO')
export const SET_TIMEZONE = 'SET_TIMEZONE'
export const SELECT_TIMEZONE = 'SELECT_TIMEZONE'
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY'
export const SET_COMPANY_COUNTRY = 'SET_COMPANY_COUNTRY'
export const GET_PASS_BY_UNREGISTERED_CHECK_EMAIL = new ApiAction(
  'GET_PASS_BY_UNREGISTERED_CHECK_EMAIL'
)
export const GET_UNCONFIRMED_USER = new ApiAction('GET_UNCONFIRMED_USER')
export const CONFIRM_BY_TOKEN = new ApiAction('CONFIRM_BY_TOKEN')
export const GET_TRANSACTIONS = new ApiAction('GET_TRANSACTIONS')
export const ADD_OPERATION_COMMENT = new ApiAction('ADD_OPERATION_COMMENT')
export const GET_OPERATIONS_REPORT = new ApiAction('GET_OPERATIONS_REPORT')

/* profile my content */
export const GET_MY_CONTENT_REQUESTS = new ApiAction('GET_MY_CONTENT_REQUESTS')
export const GET_MY_CONTENT_PROJECTS = new ApiAction('GET_MY_CONTENT_PROJECTS')
export const GET_MY_CONTENT_OPERATIONS = new ApiAction(
  'GET_MY_CONTENT_OPERATIONS'
)
export const DOWNLOAD_REQUESTED_VIDEO = new ApiAction(
  'DOWNLOAD_REQUESTED_VIDEO'
)
export const CANCEL_VIDEO_REQUEST = new ApiAction('CANCEL_VIDEO_REQUEST')

/* agreements */
export const ACCEPT_AGREEMENT = new ApiAction('ACCEPT_AGREEMENT')
export const GET_AGREEMENTS = new ApiAction('GET_AGREEMENTS')
export const GET_AGREEMENT_REQUESTS = new ApiAction('GET_AGREEMENT_REQUESTS')
export const GET_ACTIVE_AGREEMENTS = new ApiAction('GET_ACTIVE_AGREEMENTS')
export const GET_AGREEMENT_REQUEST_DATA = new ApiAction(
  'GET_AGREEMENT_REQUEST_DATA'
)
export const AGREEMENT_REQUEST = new ApiAction('AGREEMENT_REQUEST')
export const REMOVE_USER_ACCEPTING_AGREEMENTS =
  'REMOVE_USER_ACCEPTING_AGREEMENTS'
export const GET_PLAN = new ApiAction('GET_PLAN')
export const GET_PURCHASE_POSSIBILITY = new ApiAction(
  'GET_PURCHASE_POSSIBILITY'
)
export const SET_AUTO_RENEWAL = new ApiAction('SET_AUTO_RENEWAL')

/* payment  */
export const CONFIRM_VIDEO_PAYMENT = new ApiAction('CONFIRM_VIDEO_PAYMENT')
export const INITIATE_VIDEO_PAYMENT = new ApiAction('INITIATE_VIDEO_PAYMENT')
export const SUBSCRIBE_PASS_PAYMENT = new ApiAction('SUBSCRIBE_PASS_PAYMENT')
export const PROCEED = new ApiAction('PROCEED')
export const PAY = new ApiAction('PAY')
export const PP_SUBSCRIPTIONS_PROCEED = new ApiAction(
  'PP_SUBSCRIPTIONS_PROCEED'
)
export const PP_SUBSCRIPTIONS_PAY = new ApiAction('PP_SUBSCRIPTIONS_PAY')
export const GET_PAYMENT_INFO = new ApiAction('GET_PAYMENT_INFO')

/* card */
export const UPDATE_CARD = new ApiAction('UPDATE_CARD')

/* company */
export const GET_COMPANY = new ApiAction('GET_COMPANY')
export const UPDATE_COMPANY = new ApiAction('UPDATE_COMPANY')
export const INVITE_MEMBER = new ApiAction('INVITE_MEMBER')
export const SEND_INSTRUCTIONS = new ApiAction('SEND_INSTRUCTIONS')
export const UPDATE_MEMBER = new ApiAction('UPDATE_MEMBER')
export const DELETE_MEMBER = new ApiAction('DELETE_MEMBER')
export const CREATE_USER_LIMIT = new ApiAction('CREATE_USER_LIMIT')
export const UPDATE_USER_LIMIT = new ApiAction('UPDATE_USER_LIMIT')
export const DELETE_USER_LIMIT = new ApiAction('DELETE_USER_LIMIT')

/* My Lists */
export const GET_LISTS = new ApiAction('GET_LISTS')
export const GET_VIDEO_LISTS = new ApiAction('GET_VIDEO_LISTS')
export const GET_LIST_ITEMS = new ApiAction('GET_LIST_ITEMS')
export const GET_ACTIVE_LIST = new ApiAction('GET_ACTIVE_LIST')
export const UPDATE_LIST = new ApiAction('UPDATE_LIST')
export const DELETE_LIST = new ApiAction('DELETE_LIST')
export const DELETE_LIST_ITEM = new ApiAction('DELETE_LIST_ITEM')
export const ADD_LIST_ITEM = new ApiAction('ADD_LIST_ITEM')

/* Planning section */
export const GET_PLANNING_SECTION = new ApiAction('GET_PLANNING_SECTION')
export const CHECK_PLANNING_SECTION_ACCESS = new ApiAction(
  'CHECK_PLANNING_SECTION_ACCESS'
)

/* subscribe to newsletters */
export const NEWSLETTERS_POPUP_DATES = new ApiAction('NEWSLETTERS_POPUP_DATES')
export const SEND_TO_PARDOT = new ApiAction('SEND_TO_PARDOT')
