import React from 'react'
import Component from 'components/component'
import { getScrollBarWidth } from 'helpers/elements'
import modal from './base'

class SideModal extends Component {
  static defaultProps = {
    onFixedChange: () => {},
  }

  state = {
    fixed: false,
  }

  scrollable = React.createRef()

  componentDidMount() {
    this.refresh()
    window.addEventListener('resize', this.refresh, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refresh)
  }

  render() {
    return (
      <div className="side-popup-container" style={{ display: 'block' }}>
        <div className="side-popup" style={{ zIndex: 9999 }}>
          <SideModalInner style={{ padding: 0 }}>
            <a
              href="#"
              className="side-popup__close"
              onClick={this.props.close}
            />
          </SideModalInner>
          <div
            ref={this.scrollable}
            onScroll={this.refresh}
            className="side-popup-scrollable _fixed"
            style={{ marginRight: -1 * getScrollBarWidth(), height: '100%' }}
          >
            {this.props.children}
          </div>
        </div>
        <div className="overlay" onClick={this.props.close} />
      </div>
    )
  }

  refresh = () => {
    const scrollable = this.scrollable.current,
      fixed = scrollable.clientHeight < scrollable.scrollHeight

    if (fixed !== this.state.fixed) {
      this.setState({ fixed })
      this.props.onFixedChange(fixed)
    }
  }
}

export function SideModalInner(props) {
  return <div className="side-popup-inner" {...props} />
}

export function SideModalButtons(props) {
  const fixedClassName = props.fixed ? ' _fixed' : ''

  return <div className={`side-popup-buttons ${fixedClassName}`} {...props} />
}

export default modal(SideModal)
