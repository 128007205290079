import * as C from './consts'

export function getCountries() {
  return {
    types: C.GET_COUNTRIES,
    url: '/api/countries.json',
    cache: 1000 * 999, // 999sec
  }
}

export function getRegions() {
  return {
    types: C.GET_REGIONS,
    url: '/api/regions.json',
    cache: 1000 * 999, // 999sec
  }
}

export function getCountriesForFilter() {
  return {
    types: C.GET_COUNTRIES_FOR_FILTER,
    url: '/api/content/geodata/countries',
    cache: 1000 * 999, // 999sec
  }
}

export function getRegionsForFilter() {
  return {
    types: C.GET_REGIONS_FOR_FILTER,
    url: '/api/content/geodata/regions',
    cache: 1000 * 999, // 999sec
  }
}
