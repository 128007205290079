import React, { Fragment } from 'react'
import Component from 'components/component'

import { DI } from 'modules'
import { Link } from 'components/link'

import { myContent } from 'config/routes'
import LinkA from './link-a'
import { IS_LANDING } from '../../config'

export function HeaderMobileMenu(props) {
  const { strings } = props.common
  const { isSignedIn } = props.user
  const user = props.user.currentUser.payload

  const toggleMobileMenu = ev => {
    // ev.preventDefault();
    props.actions.common.toggleMobileMenu()
  }

  const signOut = ev => {
    ev.preventDefault()
    props.actions.user.signOut()
  }

  return (
    <div
      className="header-mm"
      style={props.common.mobileMenuOpen ? { display: 'block' } : {}}
    >
      <ul className="header-mm-list" onClick={toggleMobileMenu}>
        {isSignedIn && 
          <li className="header-mm-item">
            <Link to={myContent.getPath()} className="header-mm__link">
              {strings['header.menu.profile.myaccount']}
              <span className="header-profile-menu__subtext">
                {user.email}
              </span>
            </Link>
        </li>}
        {props.menu.mainMenu
          .filter(subitem => subitem.title)
          .map(item => (
            <li key={item.link} className="header-mm-item">
              <LinkA
                link={item.link}
                className={Component.classList(
                  'header-mm__link',
                  item.active && '_active'
                )}
              >
                {item.title}
              </LinkA>
            </li>
          ))}

        {isSignedIn ? (
          <li className="header-mm-item">
            <a href="#" className="header-mm__link" onClick={signOut}>
              {strings['header.menu.profile.signout']}
            </a>
          </li>
        ) : (
          <Fragment>
            <li className="header-mm-item">
              <a
                href="#"
                className="header-mm__link"
                onClick={props.openSignInModal}
                data-action-name="header button"
              >
                {strings['header.profile.signin']}
              </a>
            </li>
            {IS_LANDING && <li className="header-mm-item">
              <a
                href="#"
                className="header-mm__link"
                onClick={props.openSignUpModal}
                data-action-name="header button"
              >
                {strings['header.profile.signup']}
              </a>
            </li>}
          </Fragment>
        )}
      </ul>
    </div>
  )
}

export default DI(['user', 'menu'])(HeaderMobileMenu)
