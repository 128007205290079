import * as C from './consts'
import { boundReducer } from 'redux-upgrader'

export const mainMenu = boundReducer([], {
  [C.SET_MAIN_MENU]: (state, action) => action.menu,
})

export const aboutMenu = boundReducer([], {
  [C.SET_ABOUT_MENU]: (state, action) => action.menu,
})

export const userMenu = boundReducer([], {
  [C.SET_USER_MENU]: (state, action) => action.menu,
})
