import { combineReducers, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as config from './modules.json'

const ALLOWED_MODULES = config.modules

const actions = ALLOWED_MODULES.reduce((actions, moduleName) => {
  return {
    ...actions,
    [moduleName]: require(`./${moduleName}/actions`),
  }
}, {})

export const reducers = ALLOWED_MODULES.reduce((reducers, moduleName) => {
  try {
    return {
      ...reducers,
      [moduleName]: combineReducers(require(`./${moduleName}/reducers`)),
    }
  } catch (err) {
    return {}
  }
}, {})

export const middlewares = ALLOWED_MODULES.map(moduleName => {
  try {
    return require(`./${moduleName}/middleware`).default
  } catch (err) {
    return null
  }
}).filter(Boolean)

export function DI(modules = []) {
  return connect(
    makeMapStateToProps(modules.concat(['common'])),
    makeMapDispatchToProps(modules.concat(['common'])),
    null,
    { forwardRef: true }
  )
}

function makeMapStateToProps(modules) {
  return function (state) {
    let props = {}
    modules.forEach(name => {
      props[name] = state[name]
    })
    return props
  }
}

function makeMapDispatchToProps(modules) {
  return function (dispatch) {
    let props = {}
    modules.forEach(name => {
      if (actions.hasOwnProperty(name) && typeof actions === 'object') {
        props[name] = bindActionCreators(actions[name], dispatch)
      }
    })
    return {
      actions: props,
    }
  }
}
