import * as C from '../consts'
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'

class Company {
  not_confirmed_members = []
  members = []
  owners = {}
}

export const company = api(C.GET_COMPANY, Company)

export const memberPending = boundReducer(false, {
  [C.INVITE_MEMBER.REQUEST]: true,
  [C.SEND_INSTRUCTIONS.REQUEST]: true,
  [C.DELETE_MEMBER.REQUEST]: true,
  [C.UPDATE_MEMBER.REQUEST]: true,

  [C.INVITE_MEMBER.SUCCESS]: false,
  [C.SEND_INSTRUCTIONS.SUCCESS]: false,
  [C.DELETE_MEMBER.SUCCESS]: false,
  [C.UPDATE_MEMBER.SUCCESS]: false,

  [C.INVITE_MEMBER.FAILURE]: false,
  [C.SEND_INSTRUCTIONS.FAILURE]: false,
  [C.DELETE_MEMBER.FAILURE]: false,
  [C.UPDATE_MEMBER.FAILURE]: false,
})
