import React from 'react'

export default class Component extends React.Component {
  static classList() {
    return Array.prototype.filter.call(arguments, Boolean).join(' ')
  }

  prepareModificators(scope) {
    return scope ? [].concat(scope) : []
  }

  modificators(className, scope) {
    scope = [].concat(scope)
    return className
      ? [className].concat(scope.map(m => `${className}--${m}`))
      : []
  }

  setValue(stateKey, stateValue) {
    return () =>
      this.setState({
        [stateKey]: stateValue,
      })
  }

  autobind() {
    Object.entries(this)
      .filter(([key, value]) => typeof value === 'function')
      .forEach(([key, value]) => (this[key] = value.bind(this)))
  }

  classList = Component.classList
}
