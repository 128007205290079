import * as C from '../consts'

export function newslettersPopupDates(body) {
  return {
    types: C.NEWSLETTERS_POPUP_DATES,
    method: 'PATCH',
    url: `/api/system/newsletterrs_popup_dates`,
    body,
  }
}

export function sendToPardot(body) {
  return {
    types: C.SEND_TO_PARDOT,
    method: 'POST',
    url: `/api/current_user/send_to_pardot`,
    body,
  }
}
