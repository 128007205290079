import Component from 'components/component';
import React, { useEffect, useRef, useState } from 'react';
const { classList } = Component;

function ViewCollapse({ title, children, basedClass = 'live-target' }) {
  const head = useRef(null);
  const body = useRef(null);
  const [maxHeight, setMaxHeight] = useState();
  const [openTarg, updateOpenTarg] = useState(false);
  const handlerUpdateOpenTarg = () => updateOpenTarg(!openTarg);

  useEffect(() => {
    setMaxHeight(
      head.current.getBoundingClientRect().height +
        body.current.getBoundingClientRect().height
    );
  }, []);

  return (
    <div
      className={classList(basedClass, openTarg && '_opened')}
      style={openTarg ? { maxHeight } : void 0}
    >
      <button
        onClick={handlerUpdateOpenTarg}
        type="button"
        className={classList(`${basedClass}__head`, openTarg && '_opened')}
        ref={head}
      >
        {title}
      </button>
      <div
        className={classList(`${basedClass}-body`, openTarg && '_opened')}
        ref={body}
      >
        {children}
      </div>
    </div>
  );
}

export default ViewCollapse;
