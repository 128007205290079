import * as C from '../consts'
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'

/* AGREEMENTS */

export const agreements = api(C.GET_AGREEMENTS, Array)

export const userLimitsAgreements = api(C.GET_AGREEMENTS, Array, null, action =>
  action.data.filter(item => item.status === 'active')
)

export const agreementsRequests = api(C.GET_AGREEMENT_REQUESTS, Array)

export const activeAgreements = api(C.GET_ACTIVE_AGREEMENTS, Array)

export const activeLiveAgreements = api(
  C.GET_ACTIVE_AGREEMENTS,
  Array,
  null,
  action =>
    action.data.filter(
      item =>
        (item.agreement_type === 'one_off' || item.live_access) &&
        item.status !== 'user_accepting'
    )
)

export const activeVodAgreements = api(
  C.GET_ACTIVE_AGREEMENTS,
  Array,
  null,
  action =>
    action.data.filter(
      item => item.vod_access && item.status !== 'user_accepting'
    )
)

export const agreementRequestData = api(
  C.GET_AGREEMENT_REQUEST_DATA,
  AgreementData
)

export const agreementRequest = api(C.AGREEMENT_REQUEST, Object)

export const userAcceptingAgreements = boundReducer([], {
  [C.GET_AGREEMENTS.SUCCESS]: (state, action) =>
    (action.data || []).filter(item => item.status === 'user_accepting'),
  [C.REMOVE_USER_ACCEPTING_AGREEMENTS]: (state, action) => action.data,
})

export const haveUnacceptedAgreements = boundReducer(false, {
  [C.GET_AGREEMENTS.SUCCESS]: (state, action) =>
    Boolean(action.haveUnacceptedAgreements),
})

export const unconfirmed = api(C.GET_UNCONFIRMED_USER, Object)

export const confirmedToken = api(C.CONFIRM_BY_TOKEN, Object)

export const plan = api(C.GET_PLAN, Object)
export const purchasePossibility = api(C.GET_PURCHASE_POSSIBILITY, Object)

function AgreementData() {
  this.company_types = []
  this.countries = []
  this.job_titles = []
}

/* AGREEMENTS */
