import React from 'react'
import { DI } from 'modules'
import { Link } from 'components/link'
import { NavLink, withRouter } from 'react-router-dom'
import { myContent } from 'config/routes'

export function HeaderProfile(props) {
  const user = props.user.currentUser.payload
  const locale = props.common.locale

  const signOut = ev => {
    ev.preventDefault()
    props.actions.user.signOut().then(() => {
      props.actions.user.checkPlanningAccess().catch(() => {})
    })
  }

  const string = key => {
    return props.common.strings[`header.menu.profile.${key}`]
  }

  const menu = props.menu.userMenu

  return (
    <div className="header-profile">
      <div className="header-profile-link">
        <NavLink
          to={myContent.getPath(locale)}
          className="header-profile__open"
          activeClassName="_active"
        >
          {props.common.strings['header.profile.signedin']}
          <span className="header-profile__helper" />
        </NavLink>
        <div className="header-profile-menu">
          <ul className="header-profile-menu__list">
            <li className="header-profile-menu__item">
              <Link
                to={myContent.getPath()}
                className="header-profile-menu__link"
              >
                {string('myaccount')}
                <span className="header-profile-menu__subtext">
                  {user.email}
                </span>
              </Link>
            </li>
            {menu.map(item => (
              <li key={item.link} className="header-profile-menu__item">
                <Link to={item.link} className="header-profile-menu__link">
                  {item.title}
                </Link>
              </li>
            ))}
            <li className="header-profile-menu__item">
              <a
                href="#"
                className="header-profile-menu__link"
                onClick={signOut}
              >
                {string('signout')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DI(['user', 'common', 'menu'])(withRouter(HeaderProfile))
