import * as C from '../consts'
import { boundReducer } from 'redux-upgrader'

/* SESSION */

export const isSignedIn = boundReducer(false, {
  [C.GET_CURRENT_USER.SUCCESS]: (state, action) =>
    Boolean(action.data.current_user && action.data.current_user.id),
  [C.SIGN_OUT.SUCCESS]: false,
})

export const sendingResetPasswordInstructions = boundReducer(
  C.SEND_RESET_PASSWORD_INSTRUCTIONS,
  Object
)

export const updatingPassword = boundReducer(C.UPDATE_PASSWORD, Object)

/*  / SESSION */
