import * as C from '../consts'

export function getCompany() {
  return {
    types: C.GET_COMPANY,
    url: '/api/current_user/company',
  }
}

export function updateCompany(company) {
  return {
    types: C.UPDATE_COMPANY,
    method: 'PUT',
    url: '/api/current_user/company',
    body: { company },
  }
}

export function inviteMember(email) {
  return {
    types: C.INVITE_MEMBER,
    method: 'POST',
    url: '/api/current_user/company/members',
    body: { email },
  }
}

export function sendInstructions(userId) {
  return {
    types: C.SEND_INSTRUCTIONS,
    method: 'POST',
    url: `/api/users/${userId}/confirmation_instructions`,
  }
}

export function deleteMember(memberID) {
  return {
    types: C.DELETE_MEMBER,
    method: 'DELETE',
    url: `/api/current_user/company/members/${memberID}`,
  }
}

export function updateMember(memberID, member) {
  return {
    types: C.UPDATE_MEMBER,
    method: 'PATCH',
    url: `/api/current_user/company/members/${memberID}`,
    body: { member },
  }
}

export function createUserLimit(limit) {
  return {
    types: C.CREATE_USER_LIMIT,
    method: 'POST',
    url: `/api/current_user/user_limits`,
    body: limit,
  }
}
export function updateUserLimit(limit) {
  return {
    types: C.UPDATE_USER_LIMIT,
    method: 'PATCH',
    url: `/api/current_user/user_limits/${limit.id}`,
    body: limit,
  }
}
export function deleteUserLimit(limit) {
  return {
    types: C.DELETE_USER_LIMIT,
    method: 'DELETE',
    url: `/api/current_user/user_limits/${limit.id}`,
  }
}
