import React from 'react'
import Loading from 'components/page-loading'
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom'
import PrivateRoute from 'components/private-route'
import * as routes from 'config/routes'
import frontload from 'hoc/frontload'
import { DI } from 'modules'
import * as config from 'config'
import { IS_LANDING } from 'config'

export class Routes extends React.Component {
  static matchSpecificPages(props) {
    const videos = IS_LANDING
      ? [
          routes.videosList.path,
          routes.videosFilter.path,
          routes.landingDashboard.path,
        ]
      : [routes.videosList.path, routes.videosFilter.path]
    return videos.some(path => matchPath(props.location.pathname, path))
  }

  loadForSpecificPages = () => {
    if (Routes.matchSpecificPages(this.props)) {
      // Videos
      this.props.actions.geodata.getCountriesForFilter()
      this.props.actions.geodata.getRegionsForFilter()
      this.props.actions.tags.getTags(IS_LANDING)
    }
  }

  static preload(props) {
    const preloadList = [
      props.actions.locales.getLocales(),
      props.actions.geodata.getCountries(),
    ]

    return Promise.all(preloadList)
  }

  componentDidMount() {
    const { actions } = this.props
    actions.locales.getTimezones()
    actions.user
      .getCurrentUser()
      .then(() => {
        actions.user.checkPlanningAccess().catch(() => {})
      })
      .catch(err => {
        return err
      })

    actions.tos.getToS()
    this.loadForSpecificPages()
  }

  componentDidUpdate(prevProps) {
    const isChangedLocales =
      this.props.common.locale !== prevProps.common.locale

    if (isChangedLocales) {
      if (IS_LANDING) {
        this.props.actions.tos.getToS({ locale: 'ru' })
      } else {
        this.props.actions.tos.getToS()
      }
      this.props.actions.geodata.getCountries()
      this.loadForSpecificPages()
    }

    if (
      Routes.matchSpecificPages(this.props) &&
      !Routes.matchSpecificPages(prevProps)
    ) {
      this.loadForSpecificPages()
    }
  }

  render() {
    const { location, common, user } = this.props
    const { pathname, search } = location
    const searchParams = new URLSearchParams(search)
    const plan = searchParams.get('plan')
    const redirectUrl = searchParams.get('redirect')
    /* RPTL-1021 */
    const isPass = plan === 'pass-monthly-eur' || plan === 'pass-annually-eur'

    if (config.IS_LANDING) {
      return (
        <Switch>
          <Route {...routes.landingDashboard} />
          <Route {...routes.livesView} />
          <Route {...routes.videoView} />
          <Route {...routes.checkEmail} />
          <Route {...routes.alreadyConfirmed} />
          <Route {...routes.finalStep} />
          <Route {...routes.restorePassword} />
          <Route {...routes.restorePasswordCheck} />
          <Route {...routes.newPassword} />
          <Route {...routes.newPasswordChanged} />
          <Route {...routes.privacyPolicy} />
          <Route {...routes.cookiePolicy} />
          <Route {...routes.tos} />
          <Route {...routes.joinTeam} />
          <PrivateRoute {...routes.user} />
          <Route {...routes.notFound} />
        </Switch>
      )
    }

    if (!user.isSignedIn) {
      if (user.currentUser.error?.status === 401) {
        return (
          <Switch>
            <Route {...routes.about} />
            <Route {...routes.livesView} />
            <Route {...routes.videoView} />
            <Route {...routes.signinDeactivated} />
            <Route {...routes.privacyPolicy} />
            <Route {...routes.cookiePolicy} />
            <Route {...routes.checkEmail} />
            <Route {...routes.alreadyConfirmed} />
            <Route {...routes.finalStep} />
            <Route {...routes.restorePassword} />
            <Route {...routes.restorePasswordCheck} />
            <Route {...routes.newPassword} />
            <Route {...routes.newPasswordChanged} />
            <Route {...routes.joinTeam} />
            {pathname.indexOf('/signup') === -1 &&
              pathname.indexOf('/signin') === -1 && (
                <Redirect
                  from={pathname}
                  to={`/${common.locale}/signin?redirect=${encodeURIComponent(
                    pathname + search
                  )}`}
                />
              )}

            <Route {...routes.signin} />
            <Route {...routes.tos} />
            <Route {...routes.impressum} />
            <Route {...routes.choosePlan} />
            <Route {...routes.requestPlan} />
            <Route {...routes.careersList} />
            <Route {...routes.careersView} />
            <Route {...routes.notFound} />
          </Switch>
        )
      } else return <Loading />
    } else if (redirectUrl) {
      return <Redirect from={pathname} to={redirectUrl} />
    }

    if (common.isIE) {
      return (
        <Switch>
          <Redirect
            exact
            from={`/${common.locale}`}
            to={`/${common.locale}/old-browsers`}
          />
          <Route {...routes.oldbrowsersView} />
        </Switch>
      )
    }

    return (
      <Switch>
        {!/^\/(ru|en|ar)($|\/)/.test(pathname) && (
          <Route {...routes.notFound} />
        )}
        {common.locale === 'es' && <Route {...routes.notFound} />}
        <Redirect exact from="/" to={`/${common.locale}`} />
        {common.notFound.map(path => (
          <Route key={path} path={path} {...routes.notFoundLive} />
        ))}
        <Route {...routes.dashboard} />
        <Route {...routes.landing} />
        <Route {...routes.signin} />
        <Route {...routes.signinDeactivated} />
        {/* <Route {...routes.signup} /> */}
        <Route {...routes.checkEmail} />
        <Route {...routes.alreadyConfirmed} />
        <Route {...routes.finalStep} />
        <Route {...routes.joinTeam} />
        <Route {...routes.restorePassword} />
        <Route {...routes.restorePasswordCheck} />
        <Route {...routes.newPassword} />
        <Route {...routes.newPasswordChanged} />
        {/* <Route {...routes.broadcastEdit} /> */}

        <PrivateRoute {...routes.myEventsList} />

        <Route {...routes.videosFilter} />
        <Route {...routes.videoView} />

        <Route {...routes.impressum} />
        <Route {...routes.videosList} />

        <Route {...routes.listsView} />
        <Route {...routes.livesView} />
        <Route {...routes.livesList} />
        {/* <Route {...routes.recordingsList} /> */}
        <Route {...routes.archivedLivesList} />

        <PrivateRoute {...routes.user} />

        <Route {...routes.choosePlan} />
        <Route {...routes.requestPlan} />
        {/* <Route {...routes.broadcast} /> */}
        {/* <Route {...routes.broadcastLocation} /> */}

        <PrivateRoute
          {...routes.payment}
          ignorePrivate={
            pathname === `/${common.locale}/payment/video` ||
            (pathname === `/${common.locale}/payment/subscription` && isPass)
          }
        />

        <PrivateRoute {...routes.paymentProcessing} />

        <Route {...routes.oldbrowsersView} />
        <Route {...routes.about} />
        <Route {...routes.tos} />
        <Route {...routes.privacyPolicy} />
        <Route {...routes.cookiePolicy} />
        <Route {...routes.careersList} />
        <Route {...routes.careersView} />
        <Route {...routes.notFound} />
      </Switch>
    )
  }
}

export default withRouter(
  DI(['user', 'tos', 'locales', 'tags', 'geodata', 'releasenote'])(
    frontload(Routes, 'routes')
  )
)
