import React from 'react'
import { DI } from 'modules'

import NotificationsItem from './notifications-item'

export function Notifications(props) {
  if (!props.notifications.active.length) return null
  return (
    <div className="notifications-block">
      {props.notifications.active.map(item => (
        <NotificationsItem key={item.id} {...item} />
      ))}
    </div>
  )
}

export default DI(['notifications'])(Notifications)
