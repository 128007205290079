import * as C from '../consts'

export function getPlanningList(locale) {
  return {
    types: C.GET_PLANNING_SECTION,
    url: `/api/system/planning_sections/${locale}`,
  }
}

export function checkPlanningAccess() {
  return {
    types: C.CHECK_PLANNING_SECTION_ACCESS,
    url: `/api/system/user_access_to_planning_sections`,
  }
}
