import React from 'react'
import { DI } from 'modules'
import Cookies from 'js-cookie'

import { Modal } from 'components/modal'

export function RejectionWarningModal(props) {
  const strings = text => {
    return props.common.strings[`card.rejection.${text}`]
  }

  const cardRejectedTimes = Number(Cookies.get('cardRejectedTimes')) || 5

  return (
    <Modal>
      <div className="dialog-content">
        <span className="dialog__title">{strings('warning')}</span>
        <div className="dialog-text">
          <p>
            {strings('text_1_1')} {cardRejectedTimes} {strings('text_1_2')}.
          </p>
          {5 - cardRejectedTimes <= 0 ? (
            <p>{strings('banned')}!</p>
          ) : (
            <p>
              {strings('text_2_1')} {5 - cardRejectedTimes}{' '}
              {strings('text_2_2')}.
            </p>
          )}
        </div>
        <div className="dialog-buttons">
          <div className="dialog-button">
            <button
              type="button"
              className="button"
              onClick={props.actions.common.closeCardWarningModal}
            >
              {strings('confirm')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DI()(RejectionWarningModal)
