import * as C from './consts'

let lastSearchParams = ''

export default ({ getState, dispatch }) => {
  return next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
      action.searchIsChanged =
        lastSearchParams !== action.payload.location.search
      lastSearchParams = action.payload.location.search

      action.payload.location.state = {
        ...(action.payload.location.state || {}),
        prevPage: getState().router.location.pathname,
      }
    }

    if (action.type === C.ADD_PAGE_CLASS) {
      document.querySelector('#root').classList.add(action.className)
    }

    if (action.type === C.REMOVE_PAGE_CLASS) {
      document.querySelector('#root').classList.remove(action.className)
    }

    if (action.type === C.CLEAR_PAGE) {
      dispatch({
        type: action.value ? C.ADD_PAGE_CLASS : C.REMOVE_PAGE_CLASS,
        className: 'page--clear',
      })
    }
    return next(action)
  }
}
