import * as C from './consts'

export default ({ getState, dispatch }) => {
  return next => action => {
    if (action.type === C.GET_DASHBOARD.SUCCESS) {
      ;(function () {
        this.tags = this.tags.filter(item => item.projects.length)
      }).call(action.data)
    }

    return next(action)
  }
}
