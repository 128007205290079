import * as C from './consts'

export function getLocales() {
  return {
    types: C.GET_LOCALES,
    url: '/api/locales',
    cache: 999 * 1000,
  }
}

export function getTimezones() {
  return {
    types: C.GET_TIMEZONES,
    url: '/api/time_zones',
    cache: 999 * 1000,
  }
}
