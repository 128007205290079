import * as C from './consts'

export function getToS(searchParams) {
  return {
    types: C.GET_TOS_INFO,
    url: `/api/terms_of_service`,
    search: searchParams
  }
}

export function confirmToS() {
  return {
    types: C.SET_TOS_INFO,
    method: 'POST',
    url: `/api/current_user/accept_terms_of_service`,
  }
}
