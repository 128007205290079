import * as C from './consts'

export default ({ getState, dispatch }) => {
  return next => action => {
    const { data, type } = action

    if (type === C.GET_ENTITY_FILTERED_TAG.SUCCESS) {
      const { tags, common } = getState()
      const tag = tags.byPage.getValue(1).payload

      const updData = {
        ...tag,
        videos: data.videos,
        videos_count: data.count,
      }

      dispatch({
        type: C.GET_ENTITY_TAG.SUCCESS,
        data: updData,
        search: { page: common.searchParams.page || 1 },
      })
    }

    return next(action)
  }
}
