/* LIBRARIES  */
import React, { Fragment } from 'react'
//import PropTypes from 'prop-types';

/* HOCs */
import { DI } from 'modules'
//import page from 'hoc/page';

/* COMMON COMPONENTS */
import Component from 'components/component'
import SearchForm from 'components/search-form'
import OuterClick from 'components/outer-click'

/* LOCAL COMPONENTS */

/* HELPERS */

export class HeaderSearch extends Component {
  // STATIC PROPERTIES & METHODS
  static propTypes = {}

  static defaultProps = {}

  // GETTERS & SETTERS

  // PROPERTIES
  state = {
    vis: false,
  }

  // LIFECYCLE (constructor, etc)

  // METHODS

  show = ev => {
    ev.preventDefault()
    this.setState({
      vis: true,
    })
  }

  hide = ev => {
    if (this.state.vis) {
      this.setState({
        vis: false,
      })
    }
  }

  // RENDER METHODS
  render() {
    return (
      <Fragment>
        <a href="#" className="header__search _vis" onClick={this.show}></a>
        <OuterClick on={this.hide}>
          <div
            className={this.classList(
              'header-search',
              this.state.vis && '_vis'
            )}
          >
            <SearchForm
              onChangeFocus={focus => !focus && this.hide()}
              onSearch={this.hide}
              onReset={this.hide}
            />
          </div>
        </OuterClick>
      </Fragment>
    )
  }
}

export default DI([])(HeaderSearch)
