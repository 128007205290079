import { ApiAction } from 'redux-upgrader'

export const GET_LIST = new ApiAction('GET_LIST')
export const GET_LIST_RECORDINGS = new ApiAction('GET_LIST_RECORDINGS')
export const GET_LIST_MY_EVENTS = new ApiAction('GET_LIST_MY_EVENTS')
export const GET_LIST_ARCHIVED_EVENTS = new ApiAction(
  'GET_LIST_ARCHIVED_EVENTS'
)
export const FOLLOW_LIVE = new ApiAction('FOLLOW_LIVE')
export const UNFOLLOW_LIVE = new ApiAction('UNFOLLOW_LIVE')
export const UNFOLLOW_ALL_LIVE = new ApiAction('UNFOLLOW_ALL_LIVE')
export const GET_LIVE = new ApiAction('GET_LIVE')
export const BOOK_LIVE = new ApiAction('BOOK_LIVE')
export const RECORDING_LIVE = new ApiAction('RECORDING_LIVE')
