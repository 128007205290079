import { isServer } from 'store'

let hidden = false

export function toggleChatWindow() {
  if (!isServer) {
    window.LC_API = window.LC_API || {}
    if (!window.LC_API.on_before_load) {
      window.LC_API.on_before_load = onBeforeLoad
    }

    try {
      if (hidden) {
        window.LC_API.open_chat_window()
        window.LC_API.minimize_chat_window()
        hidden = false
      }
    } catch (err) {
      // TODO: тут написать фоллбэк
    }

    function onBeforeLoad() {
      toggleChatWindow()
    }
  }
}
