import * as C from '../consts'

/* PROFILE */

export function getCurrentUser() {
  return {
    types: C.GET_CURRENT_USER,
    url: '/api/current_user',
  }
}

export function signUp(user) {
  user.email = user.email.toLowerCase()

  return {
    types: C.SIGN_UP,
    method: 'POST',
    url: '/users',
    body: { user },
  }
}

export function updateCurrentUser(user = {}) {
  return {
    types: C.UPDATE_CURRENT_USER,
    method: 'PATCH',
    url: '/api/current_user',
    body: { user },
  }
}

export function updateBillingInfo(user = {}) {
  return {
    types: C.UPDATE_BILLING_INFO,
    method: 'PATCH',
    url: '/api/current_user',
    body: { user },
  }
}

export function getPassByUnregisteredCheckEmail(email) {
  return {
    types: C.GET_PASS_BY_UNREGISTERED_CHECK_EMAIL,
    method: 'GET',
    url: '/api/users/check_email',
    search: { email },
  }
}

export const setTimezone = timezone => ({
  type: C.SET_TIMEZONE,
  value: timezone,
})

export const selectTimezone = timezone => ({
  type: C.SELECT_TIMEZONE,
  value: timezone,
})

export const unconfirmed = token => ({
  types: C.GET_UNCONFIRMED_USER,
  method: 'GET',
  url: `/api/users/unconfirmed.json`,
  search: { token },
})

export const confirmByToken = data => ({
  types: C.CONFIRM_BY_TOKEN,
  method: 'PATCH',
  url: `/users/confirmation.json`,
  body: data,
})

export const getTransactions = () => ({
  types: C.GET_TRANSACTIONS,
  method: 'GET',
  url: `/api/current_user/payments/transactions`,
})

/* / PROFILE */

/* MY CONTENT */

export const getRequests = () => ({
  types: C.GET_MY_CONTENT_REQUESTS,
  method: 'GET',
  url: `/api/current_user/my_content/video_requests`,
})

export const cancelVideoRequest = id => ({
  types: C.CANCEL_VIDEO_REQUEST,
  method: 'DELETE',
  url: `/api/current_user/my_content/video_requests/${id}`,
})

export const wsChangeRequest = data => (dispatch, getState) => {
  const requests = getState().user.myContentRequests.payload
  const changedIndex = requests.findIndex(
    item => item.video_project.external_name === data.video_project_id
  )
  if (~changedIndex) {
    const newRequests = [...requests]
    newRequests[changedIndex].status = 'completed'
    dispatch({
      type: C.GET_MY_CONTENT_REQUESTS.SUCCESS,
      data: newRequests,
    })
  }
}

export const getEntities = (search, singlePage) => ({
  types: C.GET_MY_CONTENT_PROJECTS,
  method: 'GET',
  url: `/api/current_user/my_content/entities`,
  singlePage: singlePage,
  search: {
    per_page: 12,
    ...search,
  },
})

export const getOperationsEntity = (projectId, params = {}) => ({
  types: C.GET_MY_CONTENT_OPERATIONS,
  method: 'GET',
  url: `/api/current_user/my_content/entities/${projectId}/operations`,
  search: { ...params, id: projectId },
})

export const downloadRequestedVideo = (videoProjectID, videoRequestID) => ({
  types: C.DOWNLOAD_REQUESTED_VIDEO,
  method: 'POST',
  url: `/api/content/video_projects/${videoProjectID}/videos/requested_video_downloads`,
  search: {
    video_request_id: videoRequestID,
    video_project_id: videoProjectID,
  },
})

export const addOperationComment = (operationID, operation) => ({
  types: C.ADD_OPERATION_COMMENT,
  method: 'PATCH',
  url: `/api/current_user/my_content/operation_comments/${operationID}`,
  search: { operation },
})

export const getOperationReport = data => ({
  types: C.GET_OPERATIONS_REPORT,
  method: 'POST',
  url: `/api/current_user/agreement_operations/full_stats_reports`,
  search: { ...data },
})

/* / MY CONTENT */
