import React from 'react'
import { DI } from 'modules'
import ReleaseNotesModal from './release-notes-modal'
import { sendRequest } from 'utils/api'

class ReleaseNotes extends React.Component {
  state = {
    note: {},
  }

  componentDidMount() {
    sendRequest({
      method: 'GET',
      url: '/api/system/latest_release_notes',
    }).then(({ error, body }) => {
      if (!error) {
        this.setState({ note: body })
      }
    })
  }

  render() {
    const {
      user: {
        isSignedIn,
        currentUser: { payload: userData },
      },
    } = this.props
    const { note } = this.state
    const id = String(note.id)
    const seenReleaseNotes = Object.keys(userData.accepted_release_notes || {})
    const hasSeen = seenReleaseNotes.includes(id)

    if (!isSignedIn || hasSeen || !note.published) {
      return null
    }

    return <ReleaseNotesModal note={note} />
  }
}

export default DI(['user'])(ReleaseNotes)
