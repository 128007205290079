import React, { Fragment, useState } from 'react'
import Component from 'components/component'
import { Switch, Route } from 'react-router-dom'
import { DI } from 'modules'

import HeaderClear from './header-clear'
import HeaderLang from './header-lang'
import HeaderMain from './header-main'
import HeaderCookie from './header-cookie'
import SignIn from './sign-in'
import SignUp from './sign-up'
import { IS_LANDING } from '../../config'

export function Header(props) {
  const { common, actions } = props

  // Define state
  const [floatFixed, setFloatFixed] = useState(false)

  // Define change state methods
  const onChangeFloatFixed = newFloatFixed => {
    if (floatFixed !== newFloatFixed) {
      setFloatFixed(newFloatFixed)
    }
  }

  const toggleSignInModal = ev => {
    ev && ev.preventDefault()
    if (common.signInModal.isOpen) {
      actions.common.closeSignInModal()
    } else {
      actions.common.openSignInModal()
    }
  }

  const toggleSignUpModal = ev => {
    ev && ev.preventDefault()
    if (common.signUpModal) {
      actions.common.closeSignUpModal()
    } else {
      actions.common.openSignUpModal()
    }
  }

  const floatClassList = Component.classList(
    'float-header',
    floatFixed && '_fixed',
    common.landingPage && 'float-header--landing j-float-header'
  )

  if (common.clearPage) {
    return (
      <>
        <HeaderClear />
        {common.signInModal.isOpen && <SignIn onClose={toggleSignInModal} />}
      </>
    )
  }
  return (
    <Fragment>
      <header className={floatClassList}>
        <HeaderMain openSignInModal={toggleSignInModal} />
      </header>
      <header
        className={Component.classList(
          'header',
          common.landingPage && 'header--landing'
        )}
      >
        <div className="header-before">
          <HeaderCookie />
          <Switch>
            <Route render={() => <HeaderLang />} />
          </Switch>
        </div>
        <HeaderMain
          onChangeFixed={onChangeFloatFixed}
          openSignInModal={toggleSignInModal}
          openSignUpModal={toggleSignUpModal}
        />
      </header>
      {common.signInModal.isOpen && <SignIn onClose={toggleSignInModal} />}
      {common.signUpModal && IS_LANDING && (
        <SignUp
          onClose={toggleSignUpModal}
          openSignInModal={toggleSignInModal}
        />
      )}
    </Fragment>
  )
}

export default DI()(Header)
