import * as C from './consts'
import * as M from './models'
// import { combineReducers } from "redux";
import { boundApiReducer as api, boundReducer } from 'redux-upgrader'

const defaults = {
  viewType: 'grid',
}

export const filteredEntityTag = api(C.GET_ENTITY_FILTERED_TAG, Object)

export const list = api(C.GET_TAGS, Array)

export const byID = api(
  C.GET_TAG,
  M.Tag,
  action => action.data.slug,
  action => action.data
)

export const byPage = api(
  C.GET_ENTITY_TAG,
  M.EntityTag,
  action => action.search.page,
  action => action.data
)

export const listViewTypeLanding = boundReducer(defaults.viewType, {
  [C.SET_LIST_VIEW_TYPE_LANDING]: (state, action) => action.value,
})
