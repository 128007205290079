import * as C from './consts'

export default ({ getState, dispatch }) => {
  return next => action => {
    if (process.title === 'browser') return next(action)

    switch (action.type) {
      case C.GET_LOCALES.SUCCESS: {
        if (!Array.isArray(action.response.body)) {
          console.log('SUCCESS locales response:')
          console.log('SUCCESS locales response:', action.response.text)
          console.log('SUCCESS locales response:', action.response.statusCode)
          console.log('SUCCESS locales response:', action.response.headers)
        }
        break
      }
      case C.GET_LOCALES.FAILURE: {
        console.log('FAILURE locales response:', action.response)
        break
      }
    }
    return next(action)
  }
}
