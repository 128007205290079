import React, { useState } from 'react'
import { DI } from 'modules'
import { Modal } from 'components/modal'
import Form from 'common/components/form/new-form'
import { Submit } from '../form/button'
import { DateTime } from 'luxon'

const ModalNewslettersToPardot = props => {
  return null

  const [hide, setHide] = useState(false)
  const { user } = props
  const {
    isSignedIn,
    currentUser: { payload: userData },
  } = user
  const { newsletter_notify, news_letter_popup_showed_at } = userData
  const { strings, locale } = props.common

  const onSubmit = () => {
    props.actions.user.sendToPardot()
    setHide(true)
  }

  const updateDate = () => {
    props.actions.user.newslettersPopupDates()
    setHide(true)
  }

  if (
    hide ||
    locale !== 'en' ||
    !isSignedIn ||
    newsletter_notify ||
    DateTime.fromISO(news_letter_popup_showed_at).plus({ day: 365 }).ordinal >
      DateTime.local().ordinal
  ) {
    return null
  }

  return (
    <Modal>
      <Form onSubmit={onSubmit}>
        <div className="dialog">
          <div className="dialog-content">
            <span className="dialog__title">
              {strings['newsletters.to.pardot.title']}
            </span>
            <div className="dialog-text">
              <p>{strings['newsletters.to.pardot.body_p1']}</p>
              <p>{strings['newsletters.to.pardot.body_p2']}</p>
              <p>{strings['newsletters.to.pardot.body_p3']}</p>
            </div>
            <div className="dialog-buttons">
              <div className="dialog-button">
                <button
                  type="button"
                  className="text-button"
                  onClick={updateDate}
                >
                  {strings['newsletters.to.pardot.cancel']}
                </button>
              </div>
              <div className="dialog-button">
                <Submit>{strings['newsletters.to.pardot.accept']}</Submit>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default DI(['user'])(ModalNewslettersToPardot)
