import * as firebase from 'firebase/app'
import 'firebase/messaging'

const initializedFirebaseApp = firebase.initializeApp({
  messagingSenderId: '329105192628',
})
const messaging = initializedFirebaseApp.messaging()

messaging.usePublicVapidKey(
  'BByUsrV0ONC0SJ9c7t5ld68o5IE27sfHFcuse0oWXdD7AejY-A2bj75uEtJlijMK-0tZgNBDkUgkqFlRuMLh0oQ'
)
export { messaging }
