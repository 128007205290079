import React, { Fragment } from 'react'
import { DI } from 'modules'
import TosItem from './tos-item'

class ToS extends React.Component {
  constructor(props) {
    super(props)
  }

  closeTosModal = ev => {
    ev && ev.preventDefault()
    this.props.actions.common.closeTosModal()
    this.props.actions.user.signOut()
  }
  confirmTosModal = ev => {
    ev && ev.preventDefault()
    this.props.actions.common.closeTosModal()
    this.props.actions.user.getCurrentUser().catch(() => {})
  }

  render() {
    const isSignedIn = this.props.user.isSignedIn
    const termsAccepted = this.props.user.currentUser.payload.terms_accepted

    if (isSignedIn && !termsAccepted) {
      return (
        <Fragment>
          <TosItem
            close={this.closeTosModal}
            confirm={this.confirmTosModal}
            openTosModal={this.closeTosModal}
          />
        </Fragment>
      )
    } else {
      return null
    }
  }
}

export default DI(['user', 'tos'])(ToS)
