export class Tag {
  slug = ''
  view_type = ''
  image = {
    url: '',
    original: {
      url: '',
    },
  }
}

export class EntityTag {
  videos = []
  videos_count = 0
  lives = []
  lives_count = 0
  tag = {
    slug: '',
    view_type: '',
    image: {
      url: '',
      original: {
        url: '',
      },
    },
  }
}
