import * as C from '../consts'

export function proceedPlan(
  token,
  plan_id,
  { project_name, url_1, url_2 },
  plan_name
) {
  return function (dispatch, getState) {
    const state = getState()
    return dispatch({
      types: C.PROCEED,
      method: 'POST',
      url: `/api/current_user/proceed`,
      body: {
        token: token,
        subscription: { plan_id, project_name, url_1, url_2, plan_name },
        redirect_url:
          document.location.origin +
          `/${state.common.locale}/payment-processing`,
      },
    })
  }
}
export function proceedVideo(token) {
  return function (dispatch, getState) {
    const state = getState()
    const params = state.common.searchParams

    return dispatch({
      types: C.PROCEED,
      method: 'POST',
      url: `/api/current_user/proceed`,
      body: {
        token: token,
        video: { params },
        redirect_url:
          document.location.origin +
          `/${state.common.locale}/payment-processing`,
      },
    })
  }
}

export function pay({
  client_secret,
  livemode,
  operation_id,
  source,
  token,
  payment_approved,
}) {
  return {
    types: C.PAY,
    method: 'POST',
    url: `/api/current_user/payments`,
    body: {
      client_secret,
      livemode,
      operation_id,
      source,
      order_id: token,
      payment_approved,
    },
  }
}

export function confirmPayPalPassSubscribe(agreement_id) {
  return {
    body: { agreement_id },
    method: 'POST',
    types: C.SUBSCRIBE_PASS_PAYMENT,
    url: '/api/current_user/payments/pass/paypal/confirm',
  }
}

export function confirmPayPalVideoPayment(transaction_id) {
  return {
    body: { transaction_id },
    method: 'POST',
    types: C.CONFIRM_VIDEO_PAYMENT,
    url: '/api/current_user/payments/one_off/paypal/confirm',
  }
}

export function confirmStripeVideoPayment(payment_intent_id) {
  return {
    body: { payment_intent_id },
    method: 'POST',
    types: C.CONFIRM_VIDEO_PAYMENT,
    url: '/api/current_user/payments/one_off/stripe/confirm',
  }
}

export function initiatePayPalPassPayment(body) {
  return {
    body,
    method: 'POST',
    types: C.INITIATE_VIDEO_PAYMENT,
    url: '/api/current_user/payments/pass/paypal/initiate',
  }
}

export function initiatePayPalVideoPayment(body) {
  return {
    body,
    method: 'POST',
    types: C.INITIATE_VIDEO_PAYMENT,
    url: '/api/current_user/payments/one_off/paypal/initiate',
  }
}

export function initiateStripeVideoPayment(body) {
  return {
    body,
    method: 'POST',
    types: C.INITIATE_VIDEO_PAYMENT,
    url: '/api/current_user/payments/one_off/stripe/initiate',
  }
}

export function subscribePassStripePayment(body) {
  return {
    body,
    method: 'POST',
    types: C.SUBSCRIBE_PASS_PAYMENT,
    url: '/api/current_user/payments/pass/stripe/subscription',
  }
}

export function getInfo(operation_id) {
  return {
    types: C.GET_PAYMENT_INFO,
    url: `/api/current_user/operations/${operation_id}`,
  }
}

export function paypalSubscriptionsProceed(agreement_id) {
  return {
    types: C.PP_SUBSCRIPTIONS_PROCEED,
    method: 'POST',
    url: `/api/current_user/paypal_subscriptions/proceed`,
    body: {
      agreement_id,
      redirect_url: document.location.origin + document.location.pathname,
    },
  }
}

export function paypalSubscriptionsPay(
  agreement_id,
  external_subscription_id,
  subscription_approved
) {
  return {
    types: C.PP_SUBSCRIPTIONS_PAY,
    method: 'POST',
    url: `/api/current_user/paypal_subscriptions/pay`,
    body: { agreement_id, external_subscription_id, subscription_approved },
  }
}
