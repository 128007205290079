export class LiveEvent {
  tags = []
  stream_to = {}
  streamTo = {}
  image = {
    url: '',
    thumbnail: {},
    v273x154: {},
    v575x323: {},
    v960x540: {},
    large_brand: {},
  }
  languages = []
  location = ''
  quality = ''
}

export class MyEvents {
  count = 0
  booked_events = []
  followed_events = []
}
