import * as C from './consts'

let ID = 0

export function createNotification(params) {
  return (d, g) => {
    const id = ++ID
    const timeout = params.timeout || 5 * 1000
    const children = params.children || ''
    const notificationType = ['success', 'error', 'notify'].includes(
      params.type
    )
      ? params.type
      : 'notify'
    const buttons = (
      params.buttons instanceof Array ? params.buttons : []
    ).filter(button => {
      return (
        button &&
        button.children &&
        button.onClick &&
        typeof button.onClick === 'function'
      )
    })

    if (notificationType !== 'error' || params.timeout) {
      setTimeout(function () {
        d(deleteNotification(id))
      }, timeout)
    }

    return d({
      type: C.CREATE_NOTIFICATION,
      children,
      notificationType,
      buttons,
      id,
    })
  }
}

export function deleteNotification(id) {
  return {
    type: C.DELETE_NOTIFICATION,
    id,
  }
}
