import { ApiAction } from 'redux-upgrader'

export const GET_TARGETS = new ApiAction('GET_TARGETS')
export const GET_TARGET = new ApiAction('GET_TARGET')
export const CREATE_TARGET = new ApiAction('CREATE_TARGET')
export const DESTROY_TARGET = new ApiAction('DESTROY_TARGET')

export const GET_PROVIDERS = new ApiAction('GET_PROVIDERS')
export const GET_TVU_RECEIVERS = new ApiAction('GET_TVU_RECEIVERS')

export const GET_HLS_LINKS = new ApiAction('GET_HLS_LINKS')
