import React from 'react'
import Component from 'components/component'
import Outer from 'components/outer-click'

const DEFAULT_HEIGHT = 46 * 3.5 - 1

let ID = 0

/**
 * Выпадающий список
 *
 * @constructor
 * @this  {Dropdown}
 * @param {Object} props
 * @param {Array} props.items - Массивэлементов выпадающего списка
 * @param {any} [value=null] - Значение выбранного элемента
 * @param {String} [props.placeholder=''] - Плейсхолдер
 * @param {Function} [props.onChange=(event, value, item, index)=>{}] - Коллбэк, вызывающийся при выборе элемента
 * @param {Function} [props.getTitle=(item) => item.title] - Функция, забирающая тайтл из элемента
 * @param {Function} [props.getValue=(item) => item.value] - Функция, забирающая значение из элемента
 *
 *
 * @example
 * import Dropdown from 'components/dropdown';
 *
 * const items = [{
 *     title: 'First',
 *     value: 'first'
 * }, {
 *     title: 'Second',
 *     value: 'second'
 * }]
 *
 * <Dropdown
 *     items={items}
 * />
 *
 */
export default class Dropdown extends Component {
  static defaultProps = {
    autoSelect: false,
    placeholder: '',
    onlyArrow: false,
    canSelect: true,
    items: [],
    value: null,
    modificator: '',
    headerModificator: 'desksmall',
    valueIsPlaceholder: false,
    onChange() {},
    onOpen() {},
    wrapperAttrs: {},
    disabled: false,
    listsWithVideo: [],
    getTitle(item) {
      return item.title
    },
    getValue(item) {
      return item.value
    },
    renderItem({
      item,
      index,
      video,
      array,
      title,
      value,
      active,
      onClick,
      isHighlited,
      setRef,
      listsWithVideo,
      onlyArrow,
    }) {
      const isChecked = listsWithVideo.includes(item.id)

      return (
        <li
          className="dropdown-item"
          key={value + '-' + title + '-' + index}
          ref={node => setRef(node, index)}
        >
          <a
            href="#"
            className={Component.classList(
              'dropdown__item ',
              active && '_active',
              isChecked && '_checked',
              onlyArrow && '_only-arrow'
            )}
            onClick={ev => ev.preventDefault()}
            onMouseDown={onClick}
            style={isHighlited ? { background: '#77bc1f' } : {}}
          >
            {title}
          </a>
        </li>
      )
    },
  }

  state = {
    open: false,
    value: this.props.hasOwnProperty('value') ? this.props.value : null,
    suggests: this.props.items,
    input: '',
    inFocus: false,
    highlightedElementNumber: -1,
  }

  containerRef = React.createRef()
  dropDownRef = React.createRef()
  inputRefs = []

  setRef = (node, index) => {
    this.inputRefs[index] = node
  }

  id = 'select-search-' + ++ID

  renderField = (title, headClassName) => {
    if (this.props.onlyArrow) {
      return (
        <span
          className={this.classList(
            'dropdown__only-arrow',
            this.state.open && '_opened'
          )}
          onClick={this.open}
        />
      )
    }

    if (this.props.isInput) {
      let input = this.state.input || ''

      if (!input && this.state.value) {
        input = title
      }

      return (
        <div className="dropdown__helper">
          <input
            placeholder={this.props.placeholder}
            className="input"
            onInput={this.inputHandler}
            onChange={() => {}}
            onFocus={this.onFocus}
            onBlur={this.onBlurSearch}
            value={input}
            id={this.id}
            onKeyDown={this.handleKeyPress}
            disabled={this.props.disabled}
          />
        </div>
      )
    } else {
      return (
        <button
          type="button"
          className={this.classList(...headClassName)}
          onClick={this.open}
        >
          {title}
        </button>
      )
    }
  }

  onFocus = () => {
    this.updateSuggests()
    this.setState({
      inFocus: true,
    })
    this.open()
    if (this.state.input) {
      this.updateSuggests()
    }
  }

  updateSuggests = () => {
    let input = this.state.input
    let filteredSuggests = this.props.items.filter(person =>
      String(person.name.toLocaleLowerCase()).startsWith(
        input.toLocaleLowerCase()
      )
    )

    this.setState({ suggests: filteredSuggests })
  }

  inputHandler = ev => {
    ev.stopPropagation()
    // ev.preventDefault();
    const input = ev.target.value
    let value = this.state.value

    if (!input) {
      value = null
    }

    this.setState(
      {
        input,
        value,
      },
      () => {
        this.updateSuggests()
        if (!value) {
          const event = new Event('input', {
            bubbles: true,
          })
          this.input.dispatchEvent(event)
        }
      }
    )
  }

  onBlurSearch = ev => {
    setTimeout(() => {
      this.setState({
        input: '',
        inFocus: false,
        open: false,
      })
    }, 150)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        input: this.props.value ? this.state.input : '',
        value: this.props.value,
      })
    }

    if (prevProps.items !== this.props.items) {
      this.setState({
        suggests: this.props.items,
      })

      if (
        this.props.autoSelect &&
        this.props.items.length &&
        !this.state.value
      ) {
        const [item] = this.props.items

        this.onSelect(item, 0)(null)
      }
    }
  }

  onSelect = (item, index) => ev => {
    ev && ev.preventDefault()
    this.setState({ input: item.name })
    ev && ev.target.focus()
    const afterSelect = () => {
      setTimeout(() => {
        this.close()
        if (this.input) {
          const event = new Event('input', {
            bubbles: true,
          })
          this.input.dispatchEvent(event)
        }
        if (ev) {
          if (item.onClick) {
            item.onClick(ev, this.getValue(item), item, index)
          } else {
            this.props.onChange(ev, this.getValue(item), item, index)
          }
        }
      }, 500)
    }

    if (this.props.canSelect) {
      this.setState(
        {
          value: this.getValue(item),
        },
        afterSelect
      )
    } else {
      afterSelect()
    }
  }

  setDropDownMaxHeight() {
    const clientHeight = document.documentElement.clientHeight
    const availableHeight =
      clientHeight -
      this.containerRef.current.getBoundingClientRect().bottom -
      150

    let dropdownHeight = this.props.items.length * 46

    if (dropdownHeight > availableHeight) {
      dropdownHeight = Math.max(
        46 * (Math.floor(availableHeight / 46) + 0.5) - 1,
        DEFAULT_HEIGHT
      )
    }

    this.setState({
      dropDownMaxHeight: dropdownHeight,
    })
  }

  open = () => {
    if (!this.state.open) {
      this.props.onOpen && this.props.onOpen()
      this.setState({
        open: true,
      })

      this.setDropDownMaxHeight()
    } else {
      setTimeout(() => {
        this.setState({
          open: false,
        })
      }, 500)
    }
  }

  close = ev => {
    if (this.state.open) {
      const hasFocus = this.state.inFocus
      const isIDsMatch = ev && this.id === ev.target.id

      if (hasFocus || isIDsMatch) {
        return
      }

      setTimeout(() => {
        this.setState({
          open: false,
        })
      }, 500)
    }
  }

  getTitle(item) {
    if (typeof this.props.getTitle === 'function') {
      return this.props.getTitle(item)
    } else {
      return item[this.props.getTitle]
    }
  }

  getValue(item) {
    if (typeof this.props.getValue === 'function') {
      return this.props.getValue(item)
    } else {
      return item[this.props.getValue]
    }
  }

  handleKeyPress = async event => {
    const { suggests, highlightedElementNumber } = this.state
    if (event.keyCode === 40) {
      if (highlightedElementNumber < suggests.length - 1) {
        await this.setState({
          highlightedElementNumber: highlightedElementNumber + 1,
        })
        const scrollUp =
          this.inputRefs[this.state.highlightedElementNumber].offsetTop
        this.dropDownRef.current.scrollTo({
          top: scrollUp,
          behavior: 'smooth',
        })
      }
    } else if (event.keyCode === 38) {
      if (highlightedElementNumber > 0) {
        await this.setState({
          highlightedElementNumber: highlightedElementNumber - 1,
        })
        const scrollDown =
          this.inputRefs[this.state.highlightedElementNumber].offsetTop -
          this.inputRefs[this.state.highlightedElementNumber].clientTop
        this.dropDownRef.current.scrollTo({
          top: scrollDown,
          behavior: 'smooth',
        })
      }
    } else if (event.keyCode === 13 && highlightedElementNumber >= 0) {
      this.onSelect(
        suggests[highlightedElementNumber],
        highlightedElementNumber
      )(event)
      event.target.blur()
    }
  }

  render() {
    const { headerModificator, video, listsWithVideo, valueIsPlaceholder } =
      this.props
    const isOpen = this.state.open
    const selected = this.props.items.find(
      item => this.getValue(item) === this.state.value
    )

    const notSelected = !selected
    const title =
      valueIsPlaceholder || notSelected
        ? this.props.placeholder
        : this.getTitle(selected)

    const notPlaceholder = !this.props.placeholder
    let headClassName = ['dropdown__head']

    if (headerModificator) {
      if (headerModificator instanceof Array) {
        headClassName.push(
          ...headerModificator.map(m => `dropdown__head--${m}`)
        )
      } else {
        headClassName.push(`dropdown__head--${headerModificator}`)
      }
    }

    notSelected &&
      notPlaceholder &&
      headClassName.push(`dropdown__head--not-selected`)
    isOpen && headClassName.push('_opened')
    notSelected && notPlaceholder && headClassName.push('_placeholder')

    return (
      <div
        className={this.classList(
          'dropdown',
          this.props.modificator && `dropdown--${this.props.modificator}`,
          isOpen && '_opened',
          this.props.className
        )}
        onBlur={() => {
          this.close()
        }}
        {...this.props.wrapperAttrs}
        ref={this.containerRef}
      >
        <input
          type="hidden"
          name={this.props.name}
          value={this.state.value || ''}
          ref={el => (this.input = el)}
        />

        {this.renderField(title, headClassName)}

        {this.state.suggests && (
          <Outer on={this.close} excludes={[`.dropdown`]}>
            <div
              className={this.classList(
                'dropdown-body',
                this.props.right && 'dropdown-body--right',
                this.props.bodyModificator &&
                  `dropdown-body--${this.props.bodyModificator}`,
                isOpen && '_opened'
              )}
            >
              <div
                className="nano"
                style={{
                  overflow: 'auto',
                  maxHeight: this.state.dropDownMaxHeight,
                }}
                ref={this.dropDownRef}
              >
                <div className="nano-content" style={{ overflow: 'auto' }}>
                  <ul className="dropdown-list">
                    {this.state.suggests.map((item, index, array) =>
                      this.props.renderItem({
                        setRef: this.setRef,
                        item,
                        index,
                        array,
                        video,
                        isHighlited:
                          index === this.state.highlightedElementNumber,
                        title: this.getTitle(item),
                        value: this.getValue(item),
                        active: item === selected,
                        onClick: this.onSelect(item, index),
                        listsWithVideo,
                        onlyArrow: this.props.onlyArrow,
                      })
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Outer>
        )}
      </div>
    )
  }
}
