import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'

import Form from 'common/components/form/new-form'
import { Submit } from 'components/form/button'
import { sendRequest } from 'utils/api'

class ReleaseNotesModal extends Component {
  componentDidMount() {
    document.body.classList.add('_dialog')
  }

  componentWillUnmount() {
    document.body.classList.remove('_dialog')
  }

  onSubmit = () => {
    const {
      note,
      actions: { user },
    } = this.props

    sendRequest({
      method: 'POST',
      url: '/api/current_user/accept_release_note',
      data: { id: note.id },
    }).then(({ error }) => {
      if (!error) {
        user.getCurrentUser().catch(() => {})
      }
    })
  }

  render() {
    const {
      common: { strings, locale },
      note,
    } = this.props

    return (
      <Fragment>
        <div className="dialog-container">
          <div className="dialog dialog--release-notes">
            <div className="dialog-content">
              <span className="dialog__title">{note[`title_${locale}`]}</span>
              <div
                className="dialog-text"
                dangerouslySetInnerHTML={{
                  __html: note[`text_${locale}`],
                }}
              />
              <Form onSubmit={this.onSubmit}>
                <div className="dialog-buttons">
                  <div className="dialog-button">
                    <Submit>{strings['page.releaseNote.submit']}</Submit>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="dialog-overlay" />
      </Fragment>
    )
  }
}

export default DI(['user'])(ReleaseNotesModal)
