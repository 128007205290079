import * as C from './consts'

export function getTargets(slug) {
  return {
    types: C.GET_TARGETS,
    url: `/api/content/live/events/${slug}/targets`,
    slug,
  }
}

export function getTarget(id) {
  return {
    types: C.GET_TARGET,
    url: `/api/content/live/targets/${id}`,
  }
}

export function createTarget(slug, target = {}) {
  return {
    types: C.CREATE_TARGET,
    method: 'POST',
    url: `/api/content/live/events/${slug}/targets`,
    body: { target },
    slug,
  }
}

export function destroyTarget(slug, id) {
  return {
    types: C.DESTROY_TARGET,
    method: 'DELETE',
    url: `/api/content/live/targets/${id}`,
    slug,
  }
}

export function getProviders() {
  return {
    types: C.GET_PROVIDERS,
    url: `/api/content/live/transcoder_providers`,
  }
}

export function getTVUReceivers(username) {
  return {
    types: C.GET_TVU_RECEIVERS,
    url: `/api/content/live/tvu/receivers`,
    search: { username: username },
  }
}

export function getHLSLinks(slug, languageId) {
  return {
    types: C.GET_HLS_LINKS,
    method: 'POST',
    url: `/api/content/live/events/${slug}/tokens`,
    body: { language_id: languageId },
  }
}
