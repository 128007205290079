import * as C from './consts'

export function validate(vat_id, countryID) {
  return function (dispatch, getState) {
    const country = getState().geodata.countries.payload.find(
      country => country.id == countryID
    )

    if (country) {
      dispatch({
        types: C.VALIDATE_TAXES,
        method: 'POST',
        url: '/api/taxes/validations',
        body: {
          vat_id,
          country_code: country.code,
        },
      })
    }
  }
}

export function getPrices(search) {
  return {
    types: C.GET_PRICES,
    url: '/api/current_user/payments/prices',
    search,
  }
}

export function getPricesUnregisteredUser(search) {
  return {
    types: C.GET_PRICES,
    url: '/api/current_guest/payments/prices',
    search,
  }
}

export function updateVAT(vat_id, has_no_vat = false) {
  return {
    types: C.UPDATE_VAT,
    method: 'PATCH',
    url: '/api/current_user',
    body: {
      user: {
        company_attributes: {
          vat_id,
          has_no_vat,
        },
      },
    },
  }
}

export function updateVATUnregisteredUser(
  search = { plan: '', country_id: '', vat_id: '' }
) {
  return {
    types: C.GET_PRICES,
    method: 'GET',
    url: '/api/payments/pass_prices',
    search,
  }
}
